import React, { useState } from "react";
import {
    useParams
} from "react-router-dom";
import { motion } from "framer-motion";
import { ReactComponent as LinkedinIcon} from "images/linkedin-icon.svg";
import { ReactComponent as WebsiteIcon} from "images/website-icon.svg";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
// import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";

import Header from '../../components/headers/light'
import { distinguishedResearchForum } from "../../helpers/data"
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";
import {tutorials} from "../../helpers/data"
import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';


const Subheading = tw.span`uppercase tracking-wider text-sm`;

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full  sm:pr-8`}
  ${props =>
        props.featured &&
        css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-1/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg bg-contain bg-no-repeat border-2`}
`;
const Info = tw.div`p-8 border-2 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
const Description = tw.div``;
const FAQSContainer = tw.dl`mt-12  relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion.custom(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion.custom(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

const CardLinks = styled.div`
  ${tw`mt-6 flex`}
  .link {
    ${tw`mr-8 last:mr-0 text-teal-400 hocus:text-primary-500 transition duration-300`}
    .icon {
      ${tw`fill-current w-6 h-6`}
    }
  }
`
export default () => {
    let { speakerName } = useParams();
    const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

    const toggleQuestion = questionIndex => {
        if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
        else setActiveQuestionIndex(questionIndex);
    };

    let speaker;
    if (speakerName) {
        let speakerList = distinguishedResearchForum.filter(f => f.profileLink == speakerName);
        if (speakerList.length > 0) speaker = speakerList[0]

        if (speaker == null) {
            let otherSpeakerList = distinguishedResearchForum.filter(f => f.profileLink == speakerName);
            if (otherSpeakerList.length > 0) speaker = otherSpeakerList[0];

            //    if(speaker== null){
            //     let careerPanelistList = careerPanelists.filter(f=>f.profileLink==speakerName);
            //     if(careerPanelistList.length > 0) speaker = careerPanelistList[0];

            //    }
        }
    }
   
    return (
        <AnimationRevealPage>
            <Header />
            <Container>
                <ContentWithPaddingXl>
                    <HeadingRow>
                        <Heading>Tutorials on 12/19/2020 </Heading>
                    </HeadingRow>
                    <Posts>
                        {tutorials.map((post, index) => (
                            <PostContainer key={index} featured={post.featured}>
                                <Post className="group" >
                                    {/* <Image imageSrc={post.imageSrc} /> */}
                                    <Info>
                                        <Category>{post.category}</Category>
                                        <CreationDate>{post.date}</CreationDate>
                                        <Title>{post.title}</Title>
                                        {post.featured}
                                        {post.zoom && <span>Join Using: < a href={post.zoom} style={{color:"blue"}}>{post.zoom}</a></span>}
                                        <FAQSContainer>
                                            {post.topics.map((topic, index) => (
                                                <>
                                                <FAQ
                                                    key={index}
                                                    onClick={() => {
                                                        toggleQuestion(index);}}
                                                >
                                                    <Question>
                                                        <QuestionText><b>Topic {index + 1}: </b> {topic.title}</QuestionText>
                                                        <QuestionToggleIcon
                                                            variants={{
                                                                collapsed: { rotate: 0 },
                                                                open: { rotate: -180 }
                                                            }}
                                                            initial="collapsed"
                                                            animate={activeQuestionIndex === index ? "open" : "collapsed"}
                                                            transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                                                        >
                                                            <ChevronDownIcon />
                                                        </QuestionToggleIcon>
                                                    </Question>
                                                    <Answer
                                                        variants={{
                                                            open: { opacity: 1, height: "auto", marginTop: "16px" },
                                                            collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                                                        }}
                                                        initial="collapsed"
                                                        animate={activeQuestionIndex === index ? "open" : "collapsed"}
                                                        transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                                                    >
                                                        <p><b>Speakers : </b>{topic.speakers}</p>
                                                        {topic.summary && <p><b>Summary</b> : {topic.summary}</p>}
                                                        {topic.links && <p><b>Links</b> : {topic.links.map((link, linkIndex) => (
                                                                <span key={linkIndex} >{link.url}</span>
                                                            ))}</p>}
                                                        {/* <CardLinks style={{zIndex: "2"}} onClick={()=>alert("hello")}>
                                                        {topic.links && topic.links.map((link, linkIndex) => (
                                                                <link.icon className="icon" key={linkIndex} />
                                                            ))}
                                                            </CardLinks> */}
                                                    </Answer>
                                            
                                                </FAQ>
{topic.videoLink && <a href={topic.videoLink} target="_blank">
<div class="px-8 py-2 whitespace-no-wrap text-sm leading-5 text-black-500">
   <YoutubeIcon style={{height:"40px", paddingRight:"10px"}} class="inline" />
    <span>Zoom Recording</span>
</div>
</a>
}

                                             </>
                                             ))}
                                        </FAQSContainer>
                                    </Info>
                                </Post>
                            </PostContainer>
                        ))}
                    </Posts>
                </ContentWithPaddingXl>
            </Container>
            <Footer />
        </AnimationRevealPage>
    );
};
