import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-6.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import Agenda from "../Main";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons';

const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlanDurationSwitcher = tw.div`block w-full max-w-xl sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1 mt-8`;
const SwitchButton = styled.button`
  ${tw`w-1/2 sm:w-32 px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${props => props.active && tw`bg-primary-500 text-gray-100`}
`;
const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto py-10 lg:py-12`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-2/3 -translate-y-1/2`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300`}
`;

const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl h-24 border-b`;
const LeftColumn = tw.div`relative lg:w-4/12 lg:mt-0 flex flex-col lg:items-center lg:text-center font-bold text-xs justify-center  h-full  border-l px-4 border-gray-500 bg-red-100`;
const LeftColumn2 = tw.div`relative lg:w-8/12 lg:mt-0 flex flex-col lg:items-center lg:text-center font-bold text-xs justify-center  h-full  border-l px-4 border-gray-500 bg-gray-100`;

const DateColumnTop = tw.div`relative flex flex-col lg:flex-row text-center lg:items-center lg:w-4/12 text-sm font-bold justify-center h-full border-l border-r border-gray-500`;
const DateColumnTop2 = tw.div`relative flex flex-col lg:flex-row text-center lg:items-center lg:w-4/12 text-sm font-bold justify-center h-full border-l  border-gray-500`;
const Day3 = tw.div`relative flex flex-col lg:flex-row text-center lg:items-center lg:w-4/12 text-xs font-bold justify-center h-full border-r border-l border-gray-500 bg-indigo-100`;
const Day2 = tw.div`relative flex flex-col lg:flex-row text-center lg:items-center lg:w-4/12 text-xs font-bold justify-center h-full  border-l border-gray-500 bg-gray-200 `;

const Day2RightColumn = tw.div`relative  lg:w-6/12 lg:mt-0 flex flex-col justify-center font-bold text-xs border-r h-full `;
const RightColumn = tw.div`relative lg:w-6/12 lg:mt-0 flex flex-col justify-center font-bold text-xs border-r h-full`;
const TopLeftColumn = tw.div`relative lg:w-4/12 lg:mt-0 flex flex-col lg:items-center text-center  font-bold text-sm justify-center border-l  h-full border-gray-500`;
const TopLeftColumn2 = tw.div`relative lg:w-8/12 lg:mt-0 flex flex-col lg:items-center text-center  font-bold text-sm justify-center border-l  h-full border-gray-500`;

const SecondColumn = tw.div`relative lg:w-6/12 flex-shrink-0 text-center lg:text-left font-bold`;
const ThirdColumn = tw.div`relative mt-12 lg:w-6/12 lg:mt-0 flex flex-col justify-center `;
const TwoColumnDual = tw.div`relative flex flex-col lg:flex-row md:items-center lg:w-8/12  `;
const DateColumn = tw.div`relative flex flex-col lg:flex-row md:items-center lg:w-4/12 justify-center h-full`;

export default ({
    subheading = "Agenda",
    heading = "Agenda",
    description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    plans = null,
    primaryButtonText = "Buy Now",
    planDurations = [
        {
            text: "Overview",
            switcherText: "Overview",
            twoColumns: false
        },
        {
            text: "Dec 17",
            switcherText: "Dec 17",
            twoColumns: true
        },
        {
            text: "Dec 18",
            switcherText: "Dec 18",
            twoColumns: false
        },
        {
            text: "December 19",
            switcherText: "Dec 19",
            twoColumns: true
        }
    ]
}) => {
    const agenda = [
        {
            "time": "08:20 am to 08:30 am",
            "category": { "title": "Opening Remark", "raffle": 0 },
            "description": [
                { "title": "Younghee Park", "raffle": 0 },
            ]
        },
        {
            "time": "08:30 am to 09:20 am",
            "category": { "title": "Keynote", "raffle": 1 },
            "description": [
                { "title": 'Keynote Speaker (Emin Gun Sirer at Cornell University) (Session Chair: Younghee Park, Divyesh Jadav)', "raffle": 1 },
            ]
        },
        {
            "time": "09:30 am to 12:00 pm",
            "category": { "title": "Research", "raffle": 1 },
            "description": [
                { "title": 'Research: Application and Network Security (Session Chair: Fabio Di Troia)', "raffle": 1 },
                { "title": '(9: 30 - 10:00) Dynamic Security Analysis of Zoom, Google Meet and Microsoft Teams (Nick Gauthier and Mohammad Iftekhar Husain)', "raffle": 1 },
                { "title": '(10:00  - 10:30) A Study on Secure Encapsulation based Key Recovery System (Tae-Hoon Kim, Won-Bin Kim, Dae-Hee Seo and Im-Yeong Lee)', "raffle": 1 },
                { "title": '(10:30 - 10:50) Break (Join Social Zoom Café - Hosted by PC Members, but anyone can join.)', "raffle": 1 },
                { "title": '(10:50 - 11:20 ) Path Authentication Protocol: Based on Lightweight MAC and Nonlinear Filter Generator (Yuki Taketa, Yuta Kodera, Takuya Kusaka and Yasuyuki Nogami)', "raffle": 1 },
                { "title": '(11:20 - 11:50) Strisa: A New Regulation Architecture to Enforce Travel Rule (Wei-Tek Tsai, Dong Yang and Wang Rong) ', "raffle": 1 },
            ]
        },
        {
            "time": "12:00 pm- 12:30 pm",
            "category": { "title": "Lunch", "raffle": 0 },
            "description": [
                { "title": 'Join Social Zoom Café - Hosted by PC Members, but anyone can join.', "raffle": 0 },
            ]
        },
        {
            "time": "12:30 pm - 13:20 pm",
            "category": { "title": "Keynote", "raffle": 1 },
            "description": [
                { "title": 'Keynote Speaker (Chris Romeo, Cisco) (Session Chair: Michael Tjebben)', "raffle": 0 },
            ]
        },
        {
            "time": "13:30 pm - 15:00 pm",
            "category": { "title": "Research", "raffle": 1 },
            "description": [
                { "title": 'Research: System Security (Session Chair: Donghyun Kim)', "raffle": 0 },
                { "title": '(13: 30 - 14:00) Post-Quantum Hash-Based Signatures for Secure Boot  (Panos Kampanakis, Peter Panburana, Michael Curcio, Chirag Shroff and Md Mahbub Alam)', "raffle": 0 },
                { "title": '(14:00 - 14:30) Exploring the Coverage of Existing Hardware Vulnerabilities in Community Standards (Paul Wortman, Fatemeh Tehranipoor and John Chandy)', "raffle": 0 },
                { "title": '(14:30 - 15:00 ) MurQRI: Encrypted Multi-Layer QR Codes for Electronic Identity Management (Bonha Koo, Taegeun Moon and Hyoungshick Kim)', "raffle": 0 },
            ]
        },
        {
            "time": "15:00 pm- 15:30 pm",
            "category": { "title": "Break", "raffle": 0 },
            "description": [
                { "title": 'Join Social Zoom Café - Hosted by PC Members, but anyone can join.', "raffle": 0 },
            ]
        },
        {
            "time": "15:30 pm- 17:30 pm",
            "category": { "title": "Research", "raffle": 1 },
            "description": [
                { "title": 'Research: Blockchain and Security (Session Chair: Gokay Saldamli) ', "raffle": 0 },
                { "title": '(16:00 - 16:30) SpartanGold: A Blockchain for Education, Experimentation, and Rapid Prototyping (Thomas Austin)', "raffle": 0 },
                { "title": '(16:30 - 17:00) BIOT: A blockchain-based IoT Platform for Distributed Energy Resource Management (Mostafa Yalpanian, Naser Mirzaei, Alireza Parvizimosaed, Farid Farmani, Mehdi Parvizimosaed and Behdad Bahrami)', "raffle": 0 },
                { "title": '(17:00 - 17:30 ) A Privacy Preserving E-voting System based on Blockchain (Shubham Kumar, Wenjun Fan, Vrushali Jadhav, Sang-Yoon Chang and Younghee Park) ', "raffle": 0 },

            ]
        },
        {
            "time": "17:30 pm - 18:00 pm",
            "category": { "title": "Break", "raffle": 0 },
            "description": [
                { "title": 'Join Social Zoom Café - Hosted by PC Members, but anyone can join.', "raffle": 0 },
            ]
        },
        {
            "time": "18:00 pm - 19:30 pm",
            "category": { "title": "Orientation", "raffle": 0 },
            "description": [
                { "title": 'Blockchain Hackathon Orientation', "raffle": 0 },
            ]
        }
    ];

    const [activeDurationIndex, setActiveDurationIndex] = useState(0);
    // const currAgenda = agenda[activeDurationIndex];
    return (
        <Container>
            <div style={{ textAlign: "center" }} className="mt-5 text-sm text-gray-900">
                <span style={{ fontSize: "1.2rem", fontWeight: "600" }} className="text-black">Enter To Win !!!</span>
                <br />
                Each session (Marked with Raffle <FontAwesomeIcon icon={faTicketAlt} style={{ color: "orange" }} />) will have a raffle to select a winner who will receive a $20 Amazon gift card.
                <br />Each session will have more than one raffle times and each participant can receive more than one gift card for different sessions.
                </div>
            <ContentWithPaddingXl>
                <table class="table-auto self-center w-full">
                    <thead>
                        <tr className="h-24">
                            <th class="px-4 py-2 w-2/12  border-2 bg-blue-200 font-bold text-sm ">All timings in PST</th>
                            <th class="px-4 py-2 w-2/12  border-2 bg-blue-200 font-bold text-sm ">Category</th>
                            <th class="px-4 py-2  bg-blue-200 border-2 font-bold text-sm">December 17</th>
                        </tr>
                    </thead>
                    <tbody>
                        {agenda.map(currAgenda => {
                            return (
                                <>
                                    <tr>
                                        <td class="border-2 px-4 py-2 font-bold bg-gray-200 text-sm" rowSpan={currAgenda.description.length}>{currAgenda.time}</td>
                                        <td class="border-2 px-4 py-2 font-bold bg-red-100 text-sm" rowSpan={currAgenda.description.length}>{currAgenda.category.title} {currAgenda.category.raffle === 1 && <FontAwesomeIcon icon={faTicketAlt} style={{ color: "orange" }} />} </td>
                                        <td class="border-2 px-4 py-2 font-bold bg-yellow-100 text-sm">{currAgenda.description[0]["title"]}</td>
                                    </tr>
                                    {currAgenda.description.map((d, index) => {
                                        if (index > 0) {
                                            return (
                                                <tr className="">
                                                    <td class="border-2 bg-yellow-100 px-4 py-2 font-bold text-sm">{d.title}</td>
                                                </tr>
                                            )
                                        }
                                    })}
                                </>
                            )
                        })}
                    </tbody>
                </table>
            </ContentWithPaddingXl>
            <DecoratorBlob1 />
            <DecoratorBlob2 />
        </Container>
    );
};
