import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import back from "../../images/purple.png";
import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 bg-center bg-cover`}
  background-image: url("https://images.unsplash.com/photo-1540575467063-178a50c2df87?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80");
`;
// background-image: url(${back});
const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-16 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-xl`;
const Description = tw.span`inline-block my-4 pl-3 py-1 text-gray-100  font-medium text-sm`;

const PrimaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

export default () => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/">Home</NavLink>
      <NavLink href="/agenda">
        <div class="dropdown inline-block ">
          <button class="font-semibold py-2 rounded inline-flex items-center">
            <span class="mr-1">Agenda</span>
          </button>
          <ul class="dropdown-menu absolute hidden text-gray-700 pt-1" style={{ zIndex: "10000" }}>
          <li class=""><a class="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="/agenda">Overview</a></li>
            <li class=""><a class="rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="/program">Program</a></li>
          </ul>
        </div>
      </NavLink>
      <NavLink href="/callforpapers">
        <div class="dropdown inline-block ">
          <button class="font-semibold py-2 rounded inline-flex items-center">
            <span class="mr-1">Call For Papers</span>
          </button>
          <ul class="dropdown-menu absolute hidden text-gray-700 pt-1" style={{ zIndex: "1000" }}>
            <li class=""><a class="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="/organizers">Organizers</a></li>
            <li class=""><a class="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="/dates">Important Dates</a></li>
            <li class=""><a class="rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="/submissions">Submission</a></li>
          </ul>
        </div>
      </NavLink>
      <NavLink href="/speakers">Speakers</NavLink>
      <NavLink href="/unisec">UNISEC</NavLink>
      <NavLink href="/hackathon">Hackathon</NavLink>
      {/* <NavLink href="/sponsors">Sponsors</NavLink> */}
      <PrimaryLink css={false && tw`rounded-full`} target="_blank" href="https://cs-room.com/event/bp/5f6320cf0e102309a0e2f68a">Register</PrimaryLink>
    </NavLinks>
  ];

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        {/* <StyledHeader links={navLinks} /> */}
        <TwoColumn>
          <LeftColumn>
            <Notification>December 18, 2020</Notification>
            <Heading>
              <span>UNDERREPRESENTED GROUPS IN CYBERSECURITY</span>
              <br />
              <SlantedBackground>UNISEC</SlantedBackground>
            </Heading>
            {/* <Description><i>
                            This forum aims to inspire the next generation of women and minorities by creating a learning community of students, professors, and industry professionals in Silicon Valley and beyond. It connects future women cybersecurity practitioners from campus with leading professionals in Silicon Valley. Students are inspired by a clear path forward through meaningful engagement and practical guidance
            </i></Description> */}
          </LeftColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
