import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-6.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import Agenda from "../Main";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons';

const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlanDurationSwitcher = tw.div`block w-full max-w-xl sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1 mt-8`;
const SwitchButton = styled.button`
  ${tw`w-1/2 sm:w-32 px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${props => props.active && tw`bg-primary-500 text-gray-100`}
`;
const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto py-10 lg:py-12`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-2/3 -translate-y-1/2`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300`}
`;

const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl h-24 border-b`;
const LeftColumn = tw.div`relative lg:w-2/12 lg:mt-0 flex flex-col lg:items-center lg:text-center font-bold text-xs justify-center  h-full  border-l px-4 border-gray-500 bg-red-100`;
const LeftColumn2 = tw.div`relative lg:w-2/12 lg:mt-0 flex flex-col lg:items-center lg:text-center font-bold text-xs justify-center  h-full  border-l px-4 border-gray-500 bg-gray-100`;

const DateColumnTop = tw.div`relative flex flex-col lg:flex-row text-center lg:items-center lg:w-4/12 text-sm font-bold justify-center h-full border-l border-r border-gray-500`;
const DateColumnTop2 = tw.div`relative flex flex-col lg:flex-row text-center lg:items-center lg:w-4/12 text-sm font-bold justify-center h-full border-l  border-gray-500`;
const Day3 = tw.div`relative flex flex-col lg:flex-row text-center lg:items-center lg:w-4/12 text-xs font-bold justify-center h-full border-r border-l border-gray-500 bg-indigo-100`;
const Day2 = tw.div`relative flex flex-col lg:flex-row text-center lg:items-center lg:w-4/12 text-xs font-bold justify-center h-full  border-l border-gray-500 bg-gray-200 `;

const Day2RightColumn = tw.div`relative  lg:w-6/12 lg:mt-0 flex flex-col justify-center font-bold text-xs border-r h-full `;
const RightColumn = tw.div`relative lg:w-6/12 lg:mt-0 flex flex-col justify-center font-bold text-xs border-r h-full`;
const TopLeftColumn = tw.div`relative lg:w-2/12 lg:mt-0 flex flex-col lg:items-center text-center  font-bold text-sm justify-center border-l  h-full border-gray-500`;

const SecondColumn = tw.div`relative lg:w-6/12 flex-shrink-0 text-center lg:text-left font-bold`;
const ThirdColumn = tw.div`relative mt-12 lg:w-6/12 lg:mt-0 flex flex-col justify-center `;
const TwoColumnDual = tw.div`relative flex flex-col lg:flex-row md:items-center lg:w-8/12  `;
const DateColumn = tw.div`relative flex flex-col lg:flex-row md:items-center lg:w-4/12 justify-center h-full`;

export default ({
    subheading = "Agenda",
    heading = "Agenda",
    description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    plans = null,
    primaryButtonText = "Buy Now",
    planDurations = [
        {
            text: "Overview",
            switcherText: "Overview",
            twoColumns: false
        },
        {
            text: "Dec 17",
            switcherText: "Dec 17",
            twoColumns: true
        },
        {
            text: "Dec 18",
            switcherText: "Dec 18",
            twoColumns: false
        },
        {
            text: "December 19",
            switcherText: "Dec 19",
            twoColumns: true
        }
    ]
}) => {
    const agenda = [
        {
            "time": "08:20 am to 08:30 am",
            "description": [
                { "title": "Opening Remark", "raffle": 0 },
                { "title": "-", "raffle": 0 },
                { "title": "-", "raffle": 0 },
                { "title": "Opening Remark for Hackathon (SJSU Dean)", "raffle": 0 },
                { "title": "-", "raffle": 0 }
            ]
        },
        {
            "time": "08:30 am to 09:20 am",
            "description": [
                { "title": 'Keynote Speaker (Emin Gun Sirer at Cornell University)', "raffle": 1 },
                { "title": "Keynote Speaker (Bhunia Swarup, University of Florida)", "raffle": 1 },
                { "title": "-", "raffle": 0 },
                { "title": "Keynote Speaker (Qiong Zhang, FUJITSU NETWORK COMMUNICATIONS)", "raffle": 1 },
                { "title": "-", "raffle": 0 }
            ]
        },
        {
            "time": "09:30 am to 12:00 pm",
            "description": [
                { "title": "Research Presentation", "raffle": 1 },
                { "title": "Research Presentation", "raffle": 1 },
                { "title": "-", "raffle": 0 },
                { "title": "Tutorial by Trend Micro", "raffle": 1 },
                { "title": "Hackathon", "raffle": 0 }
            ]
        },
        {
            "time": "12:00 pm to 12:30 pm",
            "description": [
                { "title": "Lunch", "raffle": 0 },
                { "title": "Lunch", "raffle": 0 },
                { "title": "-", "raffle": 0 },
                { "title": "-", "raffle": 0 },
                { "title": "Hackathon", "raffle": 0 }
            ]
        },
        {
            "time": "12:30 pm to 13:20 pm",
            "description": [
                { "title": "Keynote Speaker (Chris Romeo, Cisco)", "raffle": 1 },
                { "title": "Keynote Speaker (Nina Taft, Google)", "raffle": 1 },
                { "title": "-", "raffle": 0 },
                { "title": "Blockchain Tutorial", "raffle": 1 },
                { "title": "Hackathon", "raffle": 0 }
            ]
        },
        {
            "time": "13:30 pm to 17:50 pm",
            "description": [
                { "title": "Research Presentation", "raffle": 1 },
                { "title": "Research Presentation", "raffle": 1 },
                { "title": "UNiSEC", "raffle": 0 },
                { "title": "Blockchain Tutorial", "raffle": 0 },
                { "title": "Hackathon", "raffle": 0 }
            ]
        },
        {
            "time": "15:00 pm to 15:55 pm",
            "description": [
                { "title": "Research Presentation", "raffle": 1 },
                { "title": "Research Presentation", "raffle": 1 },
                { "title": "Speaker (Michele D. Guel, Cisco/Melike Erol-Kantarci, University of Ottawa)", "raffle": 1 },
                { "title": "Blockchain Tutorial", "raffle": 0 },
                { "title": "Hackathon", "raffle": 0 }
            ]
        },
        {
            "time": "16:00 pm to 16:55 pm",
            "description": [
                { "title": "Research Presentation", "raffle": 1 },
                { "title": "Research Presentation", "raffle": 1 },
                { "title": "Student Posters", "raffle": 1 },
                { "title": "Award Announcement", "raffle": 0 },
                { "title": "Award Announcement", "raffle": 0 }
            ]
        },
        {
            "time": "17:00 pm to 17:55 pm",
            "description": [
                { "title": "Research Presentation", "raffle": 1 },
                { "title": "Research Presentation", "raffle": 1 },
                { "title": "Career Panel", "raffle": 1 },
                { "title": "-", "raffle": 0 },
                { "title": "-", "raffle": 0 }
            ]
        },
        {
            "time": "18:30 pm to 20:00 pm",
            "description": [
                { "title": "Blockchain Hackathon Orientation", "raffle": 0 },
                { "title": "-", "raffle": 0 },
                { "title": "-", "raffle": 0 },
                { "title": "-", "raffle": 0 },
                { "title": "-", "raffle": 0 }
            ]
        },
    ];
    const schedule = [
        {
            "time": "08:20 am to 08:30 am",
            "category": { "title": "Opening Remark", "raffle": 0 },
            "day1": [
                { "title": "Opening Remark (Younghee Park, Divyesh Jadav)", "raffle": 0 },
            ],
            "day2": [
                { "title": "-", "raffle": 0 },
            ],
            "day2rowspan": 1,

        },
        {
            "time": "08:30 am to 09:20 am",
            "category": { "title": "Keynote", "raffle": 1 },
            "day1": [
                { "title": 'Keynote Speaker (Emin Gun Sirer at Cornell University), (Session Chair: Divyesh Jadav, Younghee Park)', "raffle": 1 },
            ],
            "day2": [
                { "title": 'Keynote Speaker (Bhunia Swarup, University of Florida)', "raffle": 0 }
            ],
            "day2rowspan": 1
        },
        {
            "time": "09:30 am to 12:00 pm",
            "category": { "title": "Keynote", "raffle": 1 },
            "day1": [
                { "title": 'Research Presentation', "raffle": 1 },
                { "title": '10:30 - 10:50 (Social Break)', "raffle": 1 },
                { "title": 'Research Presentation', "raffle": 1 },
            ],
            "day2": [
                { "title": 'Distinguished Research Forum', "raffle": 0 }
            ],
            "day2rowspan": 3
        },
        {
            "time": "12:00 pm to 12:30 pm",
            "category": { "title": "Keynote", "raffle": 1 },
            "day1": [
                { "title": 'Lunch', "raffle": 1 }
            ],
            "day2": [
                { "title": 'Lunch', "raffle": 0 }
            ],
            "day2rowspan": 1
        },
        {
            "time": "12:30 pm to 13:20 pm",
            "category": { "title": "Keynote", "raffle": 1 },
            "day1": [
                { "title": 'Keynote Speaker (Chris Romeo, Cisco)', "raffle": 1 }
            ],
            "day2": [
                { "title": 'Keynote Speaker (Nina Taft, Google)', "raffle": 0 }
            ],
            "day2rowspan": 1
        },
        {
            "time": "13:30 pm to 15:00 pm",
            "category": { "title": "Keynote", "raffle": 1 },
            "day1": [
                { "title": 'Research Presentation', "raffle": 1 }
            ],
            "day2": [
                { "title": 'Research Presentation', "raffle": 0 }
            ],
            "day2rowspan": 1
        },
        {
            "time": "15:00 pm to 18:00 pm",
            "category": { "title": "Keynote", "raffle": 1 },
            "day1": [
                { "title": 'Social Break  (15:00 - 15:30 p.m.)', "raffle": 1 },
                { "title": 'Research Presentation (15:30 - 17:30 p.m.)', "raffle": 1 },
                { "title": 'Social Break  (17:30 - 18:00 p.m.)', "raffle": 1 }
            ],
            "day2": [
                { "title": 'UNiSEC at 15:00 - 18:00  (Anyone can join!) & Career Panel', "raffle": 0 }
            ],
            "day2rowspan": 3
        },
        {
            "time": "18:30 pm to 20:00 pm",
            "category": { "title": "Keynote", "raffle": 1 },
            "day1": [
                { "title": 'Blockchain Hackathon Orientation', "raffle": 1 }
            ],
            "day2": [
                { "title": '-', "raffle": 0 }
            ],
            "day2rowspan": 1
        }
    ]
    const [activeDurationIndex, setActiveDurationIndex] = useState(0);
    // const currAgenda = agenda[activeDurationIndex];
    return (
        <Container>
            <div style={{ textAlign: "center" }} className="mt-5 text-sm text-gray-600">
                <span style={{ fontSize: "1.2rem", fontWeight: "600" }} className="text-black">Enter To Win !!!</span>
                <br />
                Each session (Marked with Raffle <FontAwesomeIcon icon={faTicketAlt} style={{ color: "orange" }} />) will have a raffle to select a winner who will receive a $20 Amazon gift card.
                <br />Each session will have more than one raffle times and each participant can receive more than one gift card for different sessions.
            </div>
            <ContentWithPaddingXl>
                <table class="table-auto self-center w-full">
                    <thead>
                        <tr className="h-24">
                            <th class="px-4 py-2 w-2/12  border-2 bg-blue-200 font-bold text-sm ">All timings in PST</th>
                            {/* <th class="px-4 py-2 w-2/12  border-2 bg-blue-200 font-bold text-sm ">Category</th> */}
                            <th class="px-4 py-2  bg-blue-200 border-2 font-bold text-sm">December 17</th>
                            <th class="px-4 py-2  bg-blue-200 border-2 font-bold text-sm">December 18</th>
                            <th class="px-4 py-2  bg-blue-200 border-2 font-bold text-sm" colSpan={2}>December 19</th>
                        </tr>
                    </thead>
                    <tbody>
                        {schedule.map((currAgenda, idx) => {
                            return (
                                <>
                                    <tr>
                                        <td class="border-2 px-4 py-2 font-bold bg-gray-200 text-sm" rowSpan={currAgenda.day1.length}>{currAgenda.time}</td>
                                        {/* <td class="border-2 px-4 py-2 font-bold bg-red-100 text-sm" rowSpan={currAgenda.description.length}>{currAgenda.category.title} {currAgenda.category.raffle === 1 && <FontAwesomeIcon icon={faTicketAlt} style={{ color: "orange" }} />} </td> */}
                                        <td class="border-2 px-4 py-2 font-bold bg-yellow-100 text-sm">{currAgenda.day1[0]["title"]}</td>
                                        <td class="border-2 px-4 py-2 font-bold bg-yellow-100 text-sm" rowSpan={currAgenda.day2rowspan}>
                                            {idx === 6 ? <div>
                                                <div>UNiSEC at 15:00 - 18:00  (Anyone can join!)</div>
                                                <div>Speaker (Michele D. Guel, Cisco/Melike Erol-Kantarci, University of Ottawa)</div>
                                                <div>Student Poster </div>
                                                <hr/>
                                                <div>Career Panel </div>
                                            </div> : currAgenda.day2[0]["title"]}
                                        </td>
                                        {idx === 0 ?
                                            <td class="border-2 px-4 py-2 font-bold bg-gray-200 text-sm" colSpan={2}>Opening Remark for Hackathon (SJSU Dean) </td> :
                                            idx == 1 ? <td class="border-2 px-4 py-2 font-bold bg-gray-200 text-sm" colSpan={2}>Keynote Speaker (Qiong Zhang, Fujitsu Laboratories of America) </td> :
                                                idx == 2 ? <><td class="border-2 px-4 py-2 font-bold bg-gray-200 text-sm" rowSpan={3}>Trend Micro<br /> (9:30 a.m. - 12:00 p.m.) </td><td class="border-2 px-4 py-2 font-bold bg-gray-200 text-sm" rowSpan={7}>Hackathon <br></br>(9:30 a.m.  - 4 p.m.) </td></> :
                                                    idx == 3 ? <td class="border-2 px-4 py-2 font-bold bg-gray-200 text-sm" colSpan={1}>Lunch </td> :
                                                        idx == 4 ? <td class="border-2 px-4 py-2 font-bold bg-gray-200 text-sm" rowSpan={3}>Blockchain Tutorial (12:30 - 16:00) </td> :
                                                            idx == 7 ? <td class="border-2 px-4 py-2 font-bold bg-gray-200 text-sm" colSpan={2}>Award Announcement/Closing Conference (4 -4:30 p.m.)</td> : <td />
                                        }
                                    </tr>
                                    {currAgenda.day1.map((d, index) => {
                                        if (index > 0) {
                                            return (
                                                <tr className="">
                                                    <td class="border-2 bg-yellow-100 px-4 py-2 font-bold text-sm">{d.title}</td>
                                                </tr>
                                            )
                                        }
                                    })}
                                    {currAgenda.day2.map((d, index) => {
                                        if (index > 0) {
                                            return (
                                                <tr className="">
                                                    <td class="border-2 bg-yellow-100 px-4 py-2 font-bold text-sm">{d.title}</td>
                                                </tr>
                                            )
                                        }
                                    })}

                                </>
                            )
                        })}
                        {/* <>
                            <tr>
                                <td class="border-2 px-4 py-2 font-bold bg-gray-200 text-sm" rowSpan={1}>A</td>
                                <td class="border-2 px-4 py-2 font-bold bg-gray-200 text-sm" rowSpan={1}>A</td>
                            </tr>
                        </> */}
                    </tbody>
                </table>
            </ContentWithPaddingXl>
            {/* <ContentWithPaddingXl>
                <TwoColumn style={{ textAlign: "center" }} className="bg-blue-200">
                    <TopLeftColumn>All timings in PST</TopLeftColumn>
                    <TopLeftColumn>December 17</TopLeftColumn>
                    <DateColumnTop2>December 18</DateColumnTop2>
                    <DateColumnTop>December 19</DateColumnTop>
                </TwoColumn>
                <TwoColumn style={{ textAlign: "center" }} className="bg-gray-100">
                    <LeftColumn style={{ justifyItems: "center" }}></LeftColumn>
                    <LeftColumn2></LeftColumn2>
                    <Day2>
                        <Day2RightColumn>SVCC</Day2RightColumn>
                        <Day2RightColumn>UNiSEC</Day2RightColumn>
                    </Day2>
                    <Day3>
                        <RightColumn>SVCC</RightColumn>
                        <RightColumn>Hackathon</RightColumn>
                    </Day3>
                </TwoColumn>
                {agenda.map(currAgenda => {
                    return (

                        <TwoColumn className="">
                            <LeftColumn>
                                {currAgenda.time}
                            </LeftColumn>
                            {currAgenda.description.map(session => {
                                return (
                                    <LeftColumn>{session}</LeftColumn>
                                )
                            })}

                            <LeftColumn2>
                                {currAgenda.description[0]["title"]}
                                {currAgenda.description[0]["raffle"] > 0 &&
                                    <div style={{ textAlign: "center" }}><FontAwesomeIcon icon={faTicketAlt} style={{ color: "orange" }} /></div>
                                }
                            </LeftColumn2>
                            <Day2>
                                <Day2RightColumn>
                                    {currAgenda.description[1]["title"]}
                                    {currAgenda.description[1]["raffle"] > 0 &&
                                        <div style={{ textAlign: "center" }}><FontAwesomeIcon icon={faTicketAlt} style={{ color: "orange" }} /></div>
                                    }
                                </Day2RightColumn>
                                <Day2RightColumn>
                                    {currAgenda.description[2]["title"]}
                                    {currAgenda.description[2]["raffle"] > 0 &&
                                        <div style={{ textAlign: "center" }}><FontAwesomeIcon icon={faTicketAlt} style={{ color: "orange" }} /></div>
                                    }
                                </Day2RightColumn>
                            </Day2>
                            <Day3>
                                <RightColumn>
                                    {currAgenda.description[3]["title"]}
                                    {currAgenda.description[3]["raffle"] > 0 &&
                                        <div style={{ textAlign: "center" }}><FontAwesomeIcon icon={faTicketAlt} style={{ color: "orange" }} /></div>
                                    }
                                </RightColumn>
                                <RightColumn>
                                    {currAgenda.description[4]["title"]}
                                    {currAgenda.description[4]["raffle"] > 0 &&
                                        <div style={{ textAlign: "center" }}><FontAwesomeIcon icon={faTicketAlt} style={{ color: "orange" }} /></div>
                                    }
                                </RightColumn>
                            </Day3>
                        </TwoColumn>
                    )
                })}

            </ContentWithPaddingXl> */}
            <DecoratorBlob1 />
            <DecoratorBlob2 />
        </Container>
    );
};
