import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings";
import {SectionDescription} from "components/misc/Typography";
import { ReactComponent as TwitterIcon} from "images/twitter-icon.svg";

import { ReactComponent as GithubIcon } from "images/github-icon.svg";
//import {keyNoteSpeakers} from "../../helpers/data"
const HeadingContainer = tw.div``
export const ContentWithPaddingXl= tw.div`max-w-screen-xl mx-auto pt-20 lg:pt-24`;
const Heading = tw(SectionHeading)``
const Subheading = tw(SubheadingBase)`text-center mb-3`
const Description = tw(SectionDescription)`mx-auto text-center`

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto`
const Card = tw.div`mt-24 w-full sm:w-1/3 lg:w-1/3 flex flex-col items-center`
const CardImage = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`w-64 h-64 bg-cover bg-center rounded`}
`
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-500`}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  9
`

const CardLinks = styled.div`
  ${tw`mt-6 flex`}
  .link {
    ${tw`mr-8 last:mr-0 text-gray-400 hocus:text-primary-500 transition duration-300`}
    .icon {
      ${tw`fill-current w-6 h-6`}
    }
  }
`
export default ({
  heading = "Keynote Speakers",
  subheading = "Our",
  description = "A distinguished research forum aims to provide a high-quality research session for participants from research and industry. It will discuss emerging cybersecurity threats and defense solutions in multi-disciplinary subjects for cybersecurity.  This forum will invite three or four top-notch researchers who published their creative works in our research community.",
  speakers = []
  // cards = [
  // keyNoteSpeakers.map.
  // ]
}) => {
  return (
    <Container >
      <ContentWithPaddingXl>
        <HeadingContainer>
          {/* {subheading && <Subheading>{subheading}</Subheading>} */}
          {heading && <Heading className="mb-4">{heading}</Heading> }
          {/* {description && <Description>{description}</Description> } */}
          <div className="text-center mx-auto text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100">{description}</div>
        </HeadingContainer>
        {/* <Cards>
          {speakers.map((card, index) => (
            <Card key={index}>
                <a href={"/distinguishedspeakers/" + card.profileLink} ><CardImage imageSrc={card.imageSrc} /></a>
              <CardContent>
               <a href={"/distinguishedspeakers/" + card.profileLink}  ><span className="name">{card.name}</span></a>
               <div className="text-center"><span className="position">{card.position}</span></div>
                <CardLinks>
                  {card.links.map((link, linkIndex) => (
                    <a key={linkIndex} className="link" href={link.url} target="_blank">
                      <link.icon className="icon" />
                    </a>
                  ))}
                </CardLinks>
              </CardContent>
            </Card>
          ))}
        </Cards> */}
      </ContentWithPaddingXl>
    </Container>
  );
};
