// import React from "react";

// class Head extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//         };
//     }

//     render() {
//         return (
//             <div className="mt-8">
//                 <h1 style={{ "color": "#6415FF", fontSize: "3rem", fontWeight: "700", textAlign: "center" }}>
//                     UNDERREPRESNTED GROUPS IN CYBERSECURITY (UNISEC)
//                 </h1>
//                 <h1 style={{ fontSize: "1.5rem", fontWeight: "600", marginTop: "0px", textAlign: "center" }}>
//                     12/18/2020 from 4 p.m. to 6 p.m. (PDT)
//                 </h1>
//                 <div style={{ marginTop: "15px" }}>
//                     <i>
//                         This forum aims to inspire the next generation of women and minorities by creating a learning community of students, professors, and industry professionals in Silicon Valley and beyond. It connects future women cybersecurity practitioners from campus with leading professionals in Silicon Valley. Students are inspired by a clear path forward through meaningful engagement and practical guidance. Here are our objectives for this forum.
//                         <ul>
//                             <li>
//                                 Bring together women and minorities in cybersecurity for learning and networking.
//                             </li>
//                             <li>
//                                 Learn from women/ minority leaders for career insights in cybersecurity.
//                             </li>
//                         </ul>
//                     </i>
//                 </div>
//             </div>
//         )
//     }
// }

// export default Head;
import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "./Header";
export default () => {
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  return (
  <Hero />
  )
    };
