import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "../../components/misc/Headings";
import FAQ from "../../components/faqs/SingleCol.js";
import Pricing from "../../components/pricing/ThreePlansWithHalfPrimaryBackground.js";
import MainFeature from "../../components/features/TwoColWithButton.js";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Chairs & Judges:" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
      <MainFeature 
        subheading="Supported by Cisco"
        heading="Network Security Hackathon (NSH)"
        imageSrc={require("images/nsh.png")}
        buttonRounded={false}
        description={
            "This network security hackathon utilizes the Cisco Capture-the-Flag virtual platform. The detailed information will be given to only participants before the event."
        }
        primaryButtonText="Register"
        primaryButtonUrl="https://cs-room.com/event/bp/5f6320cf0e102309a0e2f68a"
      />
        <ContentWithPaddingXl>
          {/* <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow> */}
          <Text>
          <h2>Hackathon Details:</h2>
            <br/>
                <ul>
                    <li><strong>Hackathon Date : </strong>December 19, 2020  -  9 a.m. to 3 p.m.</li>
                    <li><strong>Award Announcement : </strong>December 19, 2020 -  3:30 p.m.</li>
                    <li>Each student can participate in each stage. </li>
                    <li>The decision will be automatically made by the real-time point system whenever a participant capture the flag. However, the honor places will not be based on the score only. Judges can select the most hard workers during the competition without give-ups. </li>
                </ul>
            <h2>Cisco Hackathon Chairs & Judges:</h2>
            <br/>
                <ul>
                    <li>Anand Kanani, Technical Solutions Architect at Cisco</li>
                    <li>Younghee Park , SJSU </li>
                    <li>Michael Tjebben , CISCO</li>
                </ul>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
