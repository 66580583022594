import React from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import { Container } from "../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "../components/headers/light.js";
import Footer from "../components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "../components/misc/Headings";
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-2`;
const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto pt-8 pb-24 lg:pt-8 lg:pb-24`;
const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const Text = styled.div`
  ${tw`text-lg text-gray-800`}
  p {
    ${tw`my-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "IOT CYBERSECURITY HACKATHON FOR STUDENTS" }) => {
    return (
        <AnimationRevealPage>
            <Header />
            <Container style={{ textAlign: "justify", textJustify: "interWord" }}>
                <ContentWithPaddingXl>
                    <div>
                        <h1 style={{ "color": "#6415FF", fontSize: "2.5rem", fontWeight: "700", textAlign: "center", marginTop: "0px", marginLeft: "0px" }}>
                            REGISTRATION
            </h1>
                    </div>

                    <HeadingRow>
                        <Heading>Conference Date: <span style={{ fontSize: "1.25rem" }}>December 17 (Thursday), 2020 – December 19 (Saturday), 2020.  (Virtual)</span></Heading>
                    </HeadingRow>
                   
    <div className="my-4"style={{textAlign:"center"}}>
              <PrimaryButton as="a" target="_blank" href="https://cs-room.com/event/bp/5faf16b4f3f35008092afab8">Register</PrimaryButton>
            </div>
                    <Text>
                        <p style={{ fontSize: "16px" }}>
                            The conference solicits research papers describing novel and previously unpublished scientific contributions to the field of cybersecurity in diverse areas. The authors need to submit your original paper to EasyChair.
            </p>
                    </Text>
                    <Text>
                        <p style={{ fontSize: "18px",color: "#6415ff", fontWeight: "600" ,margin : "15px"}}>The registration deadline is 13 December, 2020 (Hard Deadline!).</p>
                        <ul>
                            <li>Each Author per paper needs to register the conference.</li>
                            <li>If necessary, up to 2 additional pages for each paper can be purchasedfor an additional fee of US $50 per page.</li>
                            <li>Authors will require their paper ID in order to complete the registration.Please look for your paper ID in the "SVCC 2020: Notification of Decision" email sent to your registered email address</li>
                        </ul>
                       <p style={{ marginTop: "15px" , fontWeight: "600"}}>All rates listed are in USD. (The ticket price absorbs all the ticket processing fees.)</p>
                    </Text>
                    <table class="table-auto self-center w-full ">
                        <thead>
                            <tr>
                                <th class="px-4 py-2">Sr. no.</th>
                                <th class="px-4 py-2">Type</th>
                                <th class="px-4 py-2">Rate ($)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="border px-4 py-2">1</td>
                                <td class="border px-4 py-2">Author</td>
                                <td class="border px-4 py-2">200</td>
                            </tr>
                            <tr>
                                <td class="border px-4 py-2">2</td>
                                <td class="border px-4 py-2">Non-Author</td>
                                <td class="border px-4 py-2">100</td>
                            </tr>
                            <tr>
                                <td class="border px-4 py-2">3</td>
                                <td class="border px-4 py-2">Students<sup>(1)</sup></td>
                                <td class="border px-4 py-2">20</td>
                            </tr>

                            <tr>
                                <td class="border px-4 py-2">4</td>
                                <td class="border px-4 py-2">Poster Presenters<sup>(2)</sup></td>
                                <td class="border px-4 py-2">20</td>
                            </tr>
                        </tbody>
                    </table>

                    <Text style={{ fontSize: "16px" }}>
                        <p>*(1) You need to show your student ID with the ticket ID.</p>
                        <p>
                        *(2) When you register it, please leave your comment about your poster ID or paper ID, which is your paper submission number when you submit your paper on EasyChair.</p>
                        <p>
                        One paper author (except for the poster author) needs to register for this conference at the Author rate ($200). Anyone else (except for the authors and the students) need to register the "Non-Author" rate ($100). The students and the poster presenters need to register at the Student rate ($20).
                        </p>
                    </Text>
                    <HeadingRow style={{ marginTop: "30px" }}>
                        <Heading>Group Ticket Purchases</Heading>
                    </HeadingRow>
                    <Text>
                       <p style={{ fontSize: "16px" }}>Please use this link (https://cs-room.com/event/bp/5faf16b4f3f35008092afab8)  and increase the quantity of tickets in STEP 1. You can just skip all the steps and directly go to STEP 4 (Check all simply) and STEP 5 for payment. Here are the screenshots here. We will contact you when we receive your payment with your registered address.</p>
                       <p>
                           <img src="/img/GroupTicket.PNG" />
                       </p>
                    </Text>
                    <HeadingRow style={{ marginTop: "30px" }}>
                        <Heading>Cancellation Policy</Heading>
                    </HeadingRow>
                    <Text style={{ fontSize: "16px" }}>
                        <p>
                        All refund/cancellation requests must be received in writing to <strong><a href="mailto:SVCC.CONF@svcsi.org">SVCC.CONF@svcsi.org</a></strong>  by December 13, 2020. Because of the tight schedules, it would be impossible to handle your refund request after 12/13/2020. There is no refund after December 13, 2020. There will be an administrative fee of 50% of your payment for cancelled registrations.<u style={{ color: "orange" }}>Note that author registration is not refundable.</u>
                        </p>
                      </Text>
                      <div className="my-4">
              <PrimaryButton as="a" target="_blank" href="https://cs-room.com/event/bp/5faf16b4f3f35008092afab8">Register</PrimaryButton>
            </div>
                </ContentWithPaddingXl>
            </Container>
            <Footer />
        </AnimationRevealPage>
    );
};
