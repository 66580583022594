import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "../../components/misc/Headings";
import FAQ from "../../components/faqs/SingleCol.js";
import Pricing from "../../components/pricing/ThreePlansWithHalfPrimaryBackground.js";
import GetStartedLight from "components/cta/GetStartedLight";
import MainFeature1 from "components/features/TwoColWithButton.js";
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;

const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-12 md:py-12 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-3/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-9/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);
const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

export default ({ headingText = "IoT CYBERSECURITY HACKATHON FOR STUDENTS" }) => {
  return (
    <AnimationRevealPage>
      <Header />

      {/* <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow> */}
      <MainFeature1
        heading={"IoT Cybersecurity Hackathon for students"}
        description={<Text>
          {/* <p>Last updated: April 21, 2020</p> */}

          <p>
            Hackathons bring tech-savvy people together in a collaborative setting to solve problems, advance a technology or produce another type of positive outcome. One of the issues hackathons can address is cybersecurity.
          </p>
          <p>On <strong>December 19, 2020 (Saturday),</strong> this conference organizes two hackathon events.</p>
          <ul>
            <li>Network security</li>
            <li>Blockchain security</li>
          </ul>
          <p>
            Based on your interested security problem, you can register one of the hackathon event. The detailed instructions will be given during the conference dates.  You cannot participate in both hackathon events together because of time conflicts. The number of seats is limited to 100 for the network security hackathon track 1. Please reserve your spot with small registration fee ($10) which will be refundable to you after you participate in hackathon. If you didn’t participate in this hackathon after registration, you cannot get refund from us because your registration makes another student lose the chance of participation and learning.
          </p>
        </Text>
        }
        buttonRounded={false}
        primaryButtonText="Closing Registration for Hackathon"
        imageSrc="/img/hackathon.png"
      />
      <Container>
        {/* <ContentWithPaddingXl>
          <GetStartedLight heading="Our prize is Amazon gift cards!" subheading="Registration Deadline : November 30, 2020 at 11:59 p.m. (PTD)" primaryLinkText="Register" primaryLinkUrl="https://cs-room.com/event/bp/5f6320cf0e102309a0e2f68a" />
          <Text>
            <p>


              We will select the grand, the first, the second, the third place, and several honor places for each track in this Hackathon competition depending on the available funding. However, we will post the winners on our websites up to the third place.
</p>
            <p><strong>
              The award is supported by the conference sponsors and San Jose State University. Thank you so much!!!</strong></p>
            <p>
              Registration Deadline (Free): <strong>November 30, 2020 at 11:59 p.m. (PTD).</strong> </p>
            <p>
              Please reserve your spot today for this student Hackathon event through <strong><a href="https://cs-room.com/event/bp/5f6320cf0e102309a0e2f68a" target="_blank">CS ROOM (Register here!)</a></strong> which is our SVCSI learning platform.</p>
            <ul>
              <li><strong>Track 1 - Network Security Hackathon (NSH):</strong> The number of seats is only 100 due to the platform capacity. Please reserve your seat if you want to participate in.
Grand Prize ($500), First Place ($300), Second Place ($200), Third Place ($100), Honors ($50 for each).</li>
              <li><strong> Track 2 - Blockchain Hackathon on Ethereum (BSH) : </strong>
             Each team has two students. Each of the two students in the same team needs to register this blockchain hackathon event separately. But, when you register this blockchain track, please leave your note about your partner (name, affiliation, email address),
              Grand Prize ($800), First Place ($500), Second Place ($300), Third Place ($200), Honors ($50 for each).
 </li>
            </ul>

          </Text>
        </ContentWithPaddingXl> */}
        <TwoColumn>
          <ImageColumn>
            <Image className="p-2" src={"https://1000logos.net/wp-content/uploads/2016/11/Cisco-logo-768x405.png"} imageBorder={false} imageShadow={false} imageRounded={true} />
            <Image className="p-2" src={"https://seeklogo.com/images/S/san-jose-state-university-logo-114AB9FC64-seeklogo.com.png"} imageBorder={false} imageShadow={false} imageRounded={true} />
          </ImageColumn>
          <TextColumn textOnLeft={true}>
            <Text>
              <p>
                We will select the grand, the first, the second, the third place, and several honor places for each track in this Hackathon competition depending on the available funding. However, we will post the winners on our websites up to the third place.
              </p>
              <p><strong>
                The award is supported by the conference sponsors and San Jose State University. Thank you so much!!!</strong></p>
              <p>
                Registration Deadline (Free): <strong>November 30, 2020 at 11:59 p.m. (PTD).</strong> </p>
              <p>
                Please reserve your spot today for this student Hackathon event through <strong><a href="https://cs-room.com/event/bp/5f6320cf0e102309a0e2f68a" target="_blank">CS ROOM (Register here!)</a></strong> which is our SVCSI learning platform.</p>
              <ul>
                <li><strong>Track 1 - Network Security Hackathon (NSH):</strong> The number of seats is only 100 due to the platform capacity. Please reserve your seat if you want to participate in.
                  Grand Prize ($500), First Place ($300), Second Place ($200), Third Place ($100), Honors ($50 for each).</li>
                <li><strong> Track 2 - Blockchain Hackathon on Ethereum (BSH) : </strong>
             Each team has two students. Each of the two students in the same team needs to register this blockchain hackathon event separately. But, when you register this blockchain track, please leave your note about your partner (name, affiliation, email address),
              Grand Prize ($800), First Place ($500), Second Place ($300), Third Place ($200), Honors ($50 for each).
                </li>
              </ul>
            </Text>
          </TextColumn>
        </TwoColumn>
        <Pricing />
        <FAQ
          heading={<>Any Questions ?</>}
        />
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
