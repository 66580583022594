import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";
import { ContentWithPaddingXl } from "components/misc/Layouts";
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;

const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${props =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`my-2 uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
const Description = tw.div``;

const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;


const Subheading = tw.span`uppercase tracking-wider text-sm`;
const posts = [
  {
    imageSrc: "img/svcc-zoom-1.png",
    category: "SVCC Conference background",
    fileName: "svcc-zoom-background-purple",
    isPDF: false
  },
  {
    imageSrc: "img/svcc-zoom-2.png",
    category: "IOT hackathon background",
    fileName: "hackathon-zoom-background",
    isPDF: false
  },
  {
    imageSrc: "img/svcc-zoom-1-green.png",
    category: "SVCC Conference background(green)",
    fileName: "svcc-zoom-background-green",
    isPDF: false
  },
  {
    imageSrc: "img/brochure.png",
    category: "SVCC Conference Brochure",
    fileName: "files/svcc-brochure.pdf",
    isPDF: true
  }
];
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <ContentWithPaddingXl>
        <h2 style={{ fontWeight: "600" }}>Zoom backgrounds</h2>
        <Posts>
          {posts.map((post, index) => (!post.isPDF ?
            <PostContainer key={index} featured={post.featured}>
              <Post className="group" as="a" href={post.url}>
                <Image imageSrc={post.imageSrc} />
                <Info>
                  <Category>{post.category}</Category>
                  <PrimaryButton><a download={post.fileName} href={post.imageSrc}>Download</a></PrimaryButton>
                </Info>
              </Post>
            </PostContainer>
            : <PostContainer key={index} featured={post.featured}>
              <Post className="group" as="a" href={post.url}>
                <Image imageSrc={post.imageSrc} />
                <Info>
                  <Category>{post.category}</Category>
                  <PrimaryButton><a download href={post.fileName}>Download</a></PrimaryButton>
                </Info>
              </Post>
            </PostContainer>))}
        </Posts>
      </ContentWithPaddingXl>
      <Footer />
    </AnimationRevealPage>
  );
};
