import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
export const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto pb-20 lg:pb-24`;

const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)` rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-24 bg-center bg-contain bg-no-repeat relative rounded-t`}
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;
const Text = styled.div`
  ${tw`text-lg text-gray-800`}
  p {
    ${tw`my-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({
    heading = "",
    sponsors = [
        {
            imageSrc:
                "https://iteachu.uaf.edu/wp-content/uploads/sites/623/2018/03/Google-Scholar-logo.png",
            url: "https://scholar.google.com/"
        },
        {
            imageSrc:
                "https://upload.wikimedia.org/wikipedia/commons/d/d2/DBLP_Logo_320x120.png",
            url: "https://dblp.org/"
        },
        // {
        //     imageSrc:
        //         "https://miro.medium.com/max/2048/1*wuOik0-WI97IW86STIR6Gg.png",
        //     url: "https://www.semanticscholar.org/"
        // },
        // {
        //     imageSrc:
        //         "https://pbs.twimg.com/profile_images/1265652999298453504/xzLTb2C2_400x400.jpg",
        //     url: "https://clarivate.com/"
        // }
    ] }) => {

    const HeadingContainer = tw.div`font-bold`
    const Heading = tw(SectionHeading)``

    return (
        <Container>
            <ContentWithPaddingXl>
                {/* <HeadingContainer>
                    Proceedings will be submitted for indexation by:
                </HeadingContainer> */}
                {/* <TabContent
                >
                    {sponsors.map((sponsor, index) => (
                        <CardContainer key={index}>
                            <Card className="group" target="_blank" href={sponsor.url} initial="rest" whileHover="hover" animate="rest">
                                <CardImageContainer imageSrc={sponsor.imageSrc}>
                                    <CardHoverOverlay
                                        variants={{
                                            hover: {
                                                opacity: 1,
                                                height: "auto"
                                            },
                                            rest: {
                                                opacity: 0,
                                                height: 0
                                            }
                                        }}
                                        transition={{ duration: 0.3 }}
                                    >
                                        <CardButton>Visit site</CardButton>
                                    </CardHoverOverlay>
                                </CardImageContainer>
                            </Card>
                        </CardContainer>
                    ))}
                </TabContent> */}
                <HeadingContainer className="my-4 mt-0">
                We are under negotiation to publish our conference proceedings through EPiC Series on EasyChair. If we can publish it through EPiC Series, the conference proceedings will be indexed in:
                </HeadingContainer>
                <Text>
                    <ul>
                        <li style={{ fontSize: "16px" }}>
                            <a>
                                Crossref, so all publications have DOI numbers
                            </a>
                        </li>
                        <li style={{ fontSize: "16px" }}>
                            <a>
                                Scopus (some series, applications are pending for other series)
                            </a>
                        </li>
                        <li style={{ fontSize: "16px" }}>
                            <a>
                                Google Scholar
                            </a>
                        </li>
                        <li style={{ fontSize: "16px" }}>
                            <a>
                                Microsoft Academic
                            </a>
                        </li>
                        <li style={{ fontSize: "16px" }}>
                            <a>
                                Some area-depending indexes, such as DBLP
                            </a>
                        </li>
                    </ul>
                </Text>
                {/* <HeadingContainer className="my-4">
                    Proceedings will be indexed by Google Scholar, DBLP, Crossref, and Microsoft Academic.
                </HeadingContainer> */}
            </ContentWithPaddingXl>
            <DecoratorBlob1 />
            <DecoratorBlob2 />
        </Container>
    );
};

