import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import { Container } from "../misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "../headers/light.js";
import Footer from "../footers/MiniCenteredFooter.js";
import { SectionHeading } from "../misc/Headings";
import FAQ from "../faqs/SingleCol.js";
import Pricing from "../pricing/ThreePlansWithHalfPrimaryBackground.js";
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-2`;
const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto pt-8 pb-24 lg:pt-8 lg:pb-24`;
const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const Text = styled.div`
  ${tw`text-lg text-gray-800 mb-8`}
  p {
    ${tw`my-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "IOT CYBERSECURITY HACKATHON FOR STUDENTS" }) => {
    return (
        <AnimationRevealPage>
            <Header />
            <Container style={{ textAlign: "justify", textJustify: "interWord" }}>
                <ContentWithPaddingXl>
                    <div className="">
                        <h1 style={{ "color": "#6415FF", fontSize: "2.5rem", fontWeight: "700", textAlign: "center", marginTop: "0px", marginLeft: "0px" }}>
                        Instructions for Authors
                        </h1>
                        {/* <b><i>All the deadlines are based in PDT</i></b> */}
                    </div>
                    <Text style={{ fontSize: "16px" , marginTop:"20px" }}>
                        {/* <p >
                        Your camera-ready version of the paper should use IEEE's conference proceeding template. Besure to incorporate the feedback from your reviewers in the camera-readyversion.
                        </p>
                        <p>
                        Please make your footnote to indicate the following message on the first page for your final camera-ready paper. Please delete the page numbers for each page.
                        </p>
                        <ul>
                            <li>
                            <i>Permission to make digital or hard copies of all or part of this work for personal or classroom use is granted without fee provided that copies are not made or distributed for profit or commercial advantage and that copies bear this notice and the full citation on the first page. SVCC 2020, December 17-19, 2020.</i>
                            </li>
                        </ul>
                        <br/>
                        <p style={{ fontWeight: "700" }}>To-do Lists :</p>
                        <p>Here are to-do-lists for paper authors. The deadline for them is <b>December 13, 2020 in PDT </b> (except for the video presentation)</p>
                        <ul>
                            <li>
                            Please register the conference by 12/13 with the author rate. <a href="http://svcc2020.svcsi.org/registration" target="blank" className="text-purple-600 font-semibold">http://svcc2020.svcsi.org/registration.</a>
                            </li>
                            <li>
                            Please submit your camera-ready PDF version on Easychair which is the same link that you submitted your first version. <a href="https://easychair.org/conferences/?conf=svcc2020" target="blank" className="text-orange-600 font-semibold">https://easychair.org/conferences/?conf=svcc2020.</a>
                            </li> 
                            <li>
                            Please submit your copyright form and your latex files/original manuscripts through the Google form. <a href="https://forms.gle/fuZMRS9updPVYrr97" target="blank" className="text-green-600 font-semibold">https://forms.gle/fuZMRS9updPVYrr97.</a> Here is the copyright form for SVCC 2020. <a href="http://svcc2020.svcsi.org/copyright_form_svcc.pdf" target="blank" className="text-orange-600 font-semibold">Please download here.</a>
                            </li>
                            <li>
                            Please check your presentation schedule. <a href="http://svcc2020.svcsi.org/program" target="blank" className="text-pink-600 font-semibold">http://svcc2020.svcsi.org/program.</a>
                            </li>
                        </ul> */}
                        {/* <br/>
                        <p>
                        Your presentation time will be 30 minutes. We encourage an author to record presentation video (25 minutes) in advance and then she/he can provide your live Q&A session (5 minutes) for the audience. It is optional. It is okay to make a live presentation too. Based on our experience, we recommend you to use your recorded video presentation. You can share your video through this Google form in order to share it with a session chair. Please submit your video by 12/15/2020 at Midnight (PDT).  <a href="https://forms.gle/XE5J9bFxF6Jdz6GB7" target="blank" className="text-blue-600 font-semibold">https://forms.gle/XE5J9bFxF6Jdz6GB7.</a>
                        </p> */}
                        <br/>
                        <p>
                        The accepted papers in SVCC 2020 will be published through the Communications in Computer and Information Science (CCIS) book at Springer. Further instructions and guidelines will be posted here soon.
                        </p>
                        <p>
                        Please use the CCIS template to submit your camera version at Springer. Here is the link to the CCIS web page <a href="http://www.springer.com/series/7899" target="blank" className="text-orange-600 font-semibold">http://www.springer.com/series/7899</a> and you can refer to the CCIS templates there.
                        </p>
                        <p>
                        Please also read the “Author Instructions” at <a href="http://www.springer.com/gp/computer-science/lncs/conference-proceedings-guidelines" target="blank" className="text-orange-600 font-semibold">http://www.springer.com/gp/computer-science/lncs/conference-proceedings-guidelines</a>
                        </p>
                        <br/>
                        <p>
                        If you have a question, please feel free to contact us at <a href="mailto:SVCC.CONF@svcsi.org" className="text-red-600 font-semibold">SVCC.CONF@svcsi.org</a>
                        </p>
                        <br/>
                        {/* <p>
                        <i>Note that we separately sent the instructions for a poster session through EasyChair, if you have any question, please contact us too. The poster author needs to submit your copyright form through the above Google form.</i>
                        </p> */}
                    </Text>
                </ContentWithPaddingXl>
            </Container>
            <Footer />
        </AnimationRevealPage>
    );
};
