import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc:
        "https://images.squarespace-cdn.com/content/v1/5e67cd61d3d039551d9349d2/1599845712860-NJ1Y4WEBUUC8X3DASQMI/ke17ZwdGBToddI8pDm48kNhLVqbMSQe1LATz2gm9H5ZZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7XRK3dMEBRBhUpwuIcb__zrC4pJLHjKaxKSQJ74OELJkLV05aabR_pTgiF7QgOruKFXA9EXPXFMlomY/MGuel_Pic.jpg?format=500w",
      subtitle: "Subtitle",
      title: "Michele D. Guel",
      description:
        "Michele D. Guel has been an avid speaker, influencer and evangelist in the cybersecurity industry for 31 years. She joined Cisco in March 1996 as the founding member of Cisco’s internal security team. During her 24+ years at Cisco, she has worked in all facets of data, information, network security, IoT operational security excellence and has established many “firsts” at Cisco. In her current role in the Office of the CTO for the Security Business Group, she is focused on data security and privacy strategies for Cisco products and offerings. In 2010, Michele was promoted to Distinguished Engineer (DE), one of 9 female DEs across Cisco today. In 2014 she co-founded Cisco’s Women in Cybersecurity Community which focuses on developing the next generation of women cybersecurity leaders. In 2016 she received the prestigious Anita Borg’s 2016 Women of Vision Technology Leadership Award.",
      url: "https://timerse.com"
    },

    {
      imageSrc:
        "https://images.squarespace-cdn.com/content/v1/5e67cd61d3d039551d9349d2/1599845677650-97PD91WTW9PIP78J8TI8/ke17ZwdGBToddI8pDm48kM9dCTH6_dwnO5G0WDer2d1Zw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7XRK3dMEBRBhUpyhfwEu9olAVXLG3Z-x93CBfy4FkWxLyF7cOcfmS_Duxt1tWRUsbfLR12nGZfh1cfY/Melike_GB.jpg?format=500w",
      subtitle: "Subtitle",
      title: "Melike Erol-Kantarci",
      description:
        "Melike Erol-Kantarci is Tier 2 Canada Research Chair in AI-enabled Next-Generation Wireless Networks and associate professor at the School of Electrical Engineering and Computer Science at the University of Ottawa. She is the founding director of the Networked Systems and Communications Research (NETCORE) laboratory. She has over 140 peer-reviewed publications which have been cited over 5000 times and she has an h-index of 36. She has acted as the general chair and technical program chair for many international conferences and workshops; most recently TPC Chair for IEEE CAMAD 2020, track chair for IEEE Globecom 2020, IEEE SmartGridComm 2020 and IEEE CCNC 2021. Her main research interests are AI-enabled wireless networks, 5G and 6G wireless communications, smart grid, electric vehicles, Internet of things and cybersecurity. She is a senior member of the IEEE.",
      url: "https://timerse.com"
    },

    // {
    //   imageSrc:
    //     "https://images.unsplash.com/photo-1509824227185-9c5a01ceba0d?ixlib=rb-1.2.1&auto=format&fit=crop&w=658&q=80",
    //   subtitle: "Exclusive",
    //   title: "Lollapalooza, Manhattan",
    //   description:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    //   url: "https://timerse.com"
    // }
  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>Distinguished Speakers</HeadingTitle>
          <HeadingDescription>
            description.
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                <Link href={card.url}>See Event Details</Link>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
