import React from "react";
import {
  useParams
} from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
// import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import MainFeature1 from "components/features/TwoColWithButtonModified.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import Header from '../../components/headers/light'
import {careerPanelists, keyNoteSpeakers , otherSpeakers} from "../../helpers/data"

const Subheading = tw.span`uppercase tracking-wider text-sm`;


export default () => {
  let { speakerName } = useParams();
 let speaker;
  if(speakerName){
    let speakerList = keyNoteSpeakers.filter(f=>f.profileLink==speakerName);
    if(speakerList.length > 0) speaker = speakerList[0]

    if(speaker ==null){
      let otherSpeakerList = otherSpeakers.filter(f=>f.profileLink==speakerName);
       if(otherSpeakerList.length > 0) speaker = otherSpeakerList[0];

       if(speaker== null){
        let careerPanelistList = careerPanelists.filter(f=>f.profileLink==speakerName);
        if(careerPanelistList.length > 0) speaker = careerPanelistList[0];
 
       }
    }
 }
  console.log()
  return (
    <AnimationRevealPage>
     <Header />
      {/* <MainFeature1
        subheading={<Subheading>About Treact</Subheading>}
        heading="We are a modern desgin agency."
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      /> */}
      {speakerName && speaker!=undefined && <MainFeature1
        subheading={<Subheading>{speaker.position}</Subheading>}
        heading={speaker.name}
        description = {speaker.description}
        buttonRounded={false}
        imageSrc= {speaker.imageSrc} 
        textOnLeft={false}
        links = {speaker.links}
        talks={speaker.talks}
      />}
      <TeamCardGrid 
        heading="Keynote Speakers" speakers = {keyNoteSpeakers}
      />
       <TeamCardGrid 
        heading="Speakers For Career Panel"  speakers = {careerPanelists} 
      />
       <TeamCardGrid 
        heading="Speakers"  speakers = {otherSpeakers} 
      />
      <Footer />
    </AnimationRevealPage>
  );
};
