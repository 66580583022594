import React, { Component } from "react";
// import Confettii from "react-dom-confetti";
import TextLoop from "react-text-loop";
// import { Helmet } from "react-helmet";
// import { Button, Grid } from "tabler-react";

// import "./Home.css";
// import PreviouslyDrawnItemsBlock from "../../components/PreviouslyDrawnItemsBlock";
// import SiteWrapper from "../../SiteWrapper";
import Confetti from 'react-confetti'
import { Button, Row, Col } from 'antd';
import Header from '../../components/headers/light';
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container } from "../misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Footer from "../footers/MiniCenteredFooter.js";
import { SectionHeading } from "../misc/Headings";
import FAQ from "../faqs/SingleCol.js";
import Pricing from "../pricing/ThreePlansWithHalfPrimaryBackground.js";
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-2`;
const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto pt-8 pb-24 lg:pt-8 lg:pb-24`;
const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const Text = styled.div`
  ${tw`text-lg text-gray-800`}
  p {
    ${tw`my-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
const Column = tw.div`px-4 sm:px-0 sm:w-1/4 md:w-2/4 mt-12`;
const SubscribeNewsletterColumn = tw(Column)`text-center   flex flex-wrap text-center justify-center `;
const Input = tw.input`bg-gray-300 px-6 py-3 rounded sm:rounded-r-none border-2 sm:border-r-0 border-gray-400 hover:border-primary-500 focus:outline-none transition duration-300 w-24`;

const width = window.innerWidth;
const height = window.innerHeight;
const style = {
    drawForm: {
        width: "100%",
    },
};

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [
                "sabrina.lugo@sjsu.edu",
                "theo.agui@yahoo.com",
                "torinhovander@gmail.com",
                "thaumkid@unm.edu",
                "saimanasa.gadepalli@sjsu.edu",
                "bliu1013@gmail.com",
                "xqin9@ucsc.edu",
                "almuhannama63@gmail.com",
                "stevenrhowe777@gmail.com",
                "jns@usc.edu",
                "realkaya.jh@gmail.com",
                "pisces03@g.skku.edu",
                "hkjeon13@gmail.com",
                "kabbi159@gmail.com",
                "jsgnwk@gmail.com",
                "shin.jaemin@csi.skku.edu",
                "cju2725@gmail.com",
                "lamiya.nguyen@gmail.com",
                "sutan45@gmail.com",
                "tekltnbch@gmail.com",
                "tekltnbch@gmail.com",
                "sagedathan.suarez@sjsu.edu",
                "mvanamal@aggies.ncat.edu",
                "alexander.shapiro@sjsu.edu",
                "darrencobian98@gmail.com",
                "aragort_alfaro_angie@student.smc.edu",
                "hammond_crystal_c@student.smc.edu",
                "acszyman@usc.edu",
                "vsarava000@citymail.cuny.edu",
                "nachary000@citymail.cuny.edu",
                "sghandali@scu.edu",
                "yan.chen01@sjsu.edu",
                "fanwenjun2010@gmail.com",
                "pankab@gmail.com",
                "id2thomas@gmail.com",
                "paul.wortman@uconn.edu",
                "hyoung@skku.edu",
                "mihusain@cpp.edu",
                "yuki2taketa@s.okayama-u.ac.jp",
                "googl0703@gmail.com",
                "ali.reza.parvizi.mosaed@gmail.com",
                "yangdong2019@buaa.edu.cn",
                "ninataft@yahoo.com",
                "attilaayavuz@usf.edu",
                "aimecca@gmail.com",
                "swhyun77@gmail.com",
                "xhyuan@ncat.edu",
                "cjs1992@skku.edu"
            ],
            currentUsers: [
                "sabrina.lugo@sjsu.edu",
                "theo.agui@yahoo.com",
                "torinhovander@gmail.com",
                "thaumkid@unm.edu",
                "saimanasa.gadepalli@sjsu.edu",
                "bliu1013@gmail.com",
                "xqin9@ucsc.edu",
                "almuhannama63@gmail.com",
                "stevenrhowe777@gmail.com",
                "jns@usc.edu",
                "realkaya.jh@gmail.com",
                "pisces03@g.skku.edu",
                "hkjeon13@gmail.com",
                "kabbi159@gmail.com",
                "jsgnwk@gmail.com",
                "shin.jaemin@csi.skku.edu",
                "cju2725@gmail.com",
                "lamiya.nguyen@gmail.com",
                "sutan45@gmail.com",
                "tekltnbch@gmail.com",
                "tekltnbch@gmail.com",
                "sagedathan.suarez@sjsu.edu",
                "mvanamal@aggies.ncat.edu",
                "alexander.shapiro@sjsu.edu",
                "darrencobian98@gmail.com",
                "aragort_alfaro_angie@student.smc.edu",
                "hammond_crystal_c@student.smc.edu",
                "acszyman@usc.edu",
                "vsarava000@citymail.cuny.edu",
                "nachary000@citymail.cuny.edu",
                "sghandali@scu.edu",
                "yan.chen01@sjsu.edu",
                "fanwenjun2010@gmail.com",
                "pankab@gmail.com",
                "id2thomas@gmail.com",
                "paul.wortman@uconn.edu",
                "hyoung@skku.edu",
                "mihusain@cpp.edu",
                "yuki2taketa@s.okayama-u.ac.jp",
                "googl0703@gmail.com",
                "ali.reza.parvizi.mosaed@gmail.com",
                "yangdong2019@buaa.edu.cn",
                "ninataft@yahoo.com",
                "attilaayavuz@usf.edu",
                "aimecca@gmail.com",
                "swhyun77@gmail.com",
                "xhyuan@ncat.edu",
                "cjs1992@skku.edu"
            ],
            wonUsers: [],
            winner: "",
            displayWinner: false,
            authenticated: false,
            password: ""
        };
    }

    pickWinnerDelay = () => {
        return new Promise((resolve) => setTimeout(resolve, 5000));
    };

    randomDrawItem = () => {
        const { currentUsers } = this.state;
        this.setState({
            ...this.state,
            displayWinner: false,
            disableButton: true,
        });
        const idx = Math.floor(Math.random() * currentUsers.length);
        this.pickWinnerDelay().then(() => {
            this.setState({
                ...this.state,
                winner: currentUsers[idx],
                wonUsers: [
                    ...this.state.wonUsers,
                    currentUsers[idx],
                ],
                displayWinner: true,
                disableButton: false,
            });
        });
        // if (true) {
        const tmp = [...this.state.currentUsers];
        tmp.splice(idx, 1);
        this.setState({
            currentUsers: tmp,
        });
        // }
    };

    onChange = (e) => {
        console.log(e.target.value)
        this.setState({
            password: e.target.value
        })
    }

    authenticate = () => {
        if (this.state.password === "svcc2020!@#$") {
            this.setState({
                authenticated: true
            })
        }
    }

    render() {
        const {
            currentUsers,
            winner,
            disableButton,
            wonUsers,
            displayWinner,
            users
        } = this.state;
        return (
            <AnimationRevealPage>
                <Header />
                {
                    this.state.displayWinner &&
                    <div>
                        <Confetti
                            confettiSource={{ 'x': 0, 'y': 130, 'w': 0, 'h': 90 }}
                            initialVelocityX={9}
                        />
                        <Confetti
                            confettiSource={{ 'x': width, 'y': 130, 'w': 0, 'h': 90 }}
                            initialVelocityX={9}
                        />
                    </div>
                }

                {!this.state.authenticated ?

                    <div style={{ textAlign: "center", alignItems: "center" }} className="mt-16">
                        <div style={{ display: "block", textAlign: "center" }}>
                            <Input type="password" placeholder="Enter Password" style={{ textAlign: "center", width: "300px" }} onChange={this.onChange} />
                            <PrimaryButton
                                onClick={this.authenticate}
                                className="ml-2"
                            >
                                Submit
                            </PrimaryButton>
                        </div>
                    </div>
                    :
                    <Container style={{ textAlign: "justify", textJustify: "interWord" }}>
                        <ContentWithPaddingXl>
                            <div className="">
                                <h1 style={{ "color": "#6415FF", fontSize: "2.25rem", fontWeight: "700", textAlign: "center", marginTop: "0px", marginLeft: "0px" }}>
                                    SILICON VALLEY CYBERSECURITY CONFERENCE 2020
                            </h1>
                            </div>
                            {users.length !== 0 && (
                                <div className="mt-8">
                                    <div className="mt-24" style={{ textAlign: "center" }}>
                                        {!displayWinner && users && (
                                            <TextLoop
                                                className="font-large text-3xl"
                                                style={{ "backgroundImage": "linear-gradient(to left, violet, indigo, green, blue, yellow, orange, red)" }}
                                                interval={50}
                                                delay={100}
                                                springConfig={{ stiffness: 180, damping: 8 }}
                                                children={users}
                                                fade={true}
                                            />
                                        )}
                                        {displayWinner &&
                                            <div >
                                                <div style={{ "color": "#6415FF", fontSize: "1.5rem", fontWeight: "700", textAlign: "center" }}>
                                                    Congratulations
                                            </div>
                                                <div style={{ fontSize: "2rem", fontWeight: "700", textAlign: "center" }}>
                                                    {winner}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div style={{ textAlign: "center" }} className="mt-16">
                                        <PrimaryButton
                                            onClick={this.randomDrawItem}
                                            disabled={disableButton || currentUsers.length <= 1}
                                        >
                                            {disableButton ? "Picking a Winner..." : "Start Raffle"}
                                        </PrimaryButton>
                                    </div>
                                    {wonUsers.length > 0 &&
                                        <div>
                                            <div style={{ "color": "#6415FF", fontSize: "1rem", fontWeight: "700", textAlign: "center" }} className="mt-24">
                                                Previous Winners
                                        </div>
                                            <div>
                                                {wonUsers.map(item => {
                                                    return (
                                                        <div style={{ textAlign: "center" }}>
                                                            {item}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    }
                                </div>
                            )}
                        </ContentWithPaddingXl>
                    </Container>
                }
            </AnimationRevealPage>
        );
    }
}

export default App;
