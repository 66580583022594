import React, { useState } from "react";
import tw from "twin.macro";
import {
    useParams
} from "react-router-dom";
import styled from "styled-components";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Document, Page } from "react-pdf";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-6.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import Header from './../components/headers/light'
import Footer from './../components/footers/MiniCenteredFooter.js';
const HeadingRow = tw.div`flex`;
const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlanDurationSwitcher = tw.div`block w-full max-w-xl sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1 mt-8`;
const SwitchButton = styled.button`
  ${tw`w-1/2 sm:w-32 px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${props => props.active && tw`bg-primary-500 text-gray-100`}
`;
const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto py-10 lg:py-12`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-2/3 -translate-y-1/2`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300`}
`;

export default ({

}) => {
    const papers = [
        {
            "title": "Dynamic Security Analysis of Zoom, Google Meet and Microsoft Teams",
            "author": "Nicholas Hunter Gauthier , Mohammad Iftekhar Husain",
            "link": "http://drive.google.com/file/d/17StB4-Co-T3xRvq4CrU0n7G0pSOxmwXV/embed",
            "abstract": `Videoconferencing platforms have received a large
            influx of traffic due to COVID-19 and the global pandemic it
            has caused. Professionals, students, politicians, and high-risk
            security individuals utilize these platforms to communicate with
            their colleagues. They blindly trust these platforms without
            understanding how secure they are, and their sensitive data
            may be vulnerable as it is transmitted through these third party
            platforms. Zoom, Google Meet, and Microsoft Teams are the
            most popular among the platforms, and they are examined in
            this paper. These platforms’ security aspects were to be evaluated
            by simulating calls within a virtual environment, capturing the
            network traffic sent to/from the network, and analyzing the
            captured traffic to determine what information outside listeners
            had access to. It was discovered that these platforms utilized
            state-of-the-art encryption methods, but they did not provide
            end-to-end encryption since vendors as third parties have access
            to the end-user communication data. It was also discovered
            that some strange TCP connections were being established as
            well as unknown DNS communications. It also found TCP
            communications running in the background. This suggests that
            the platforms are not being transparent with users, and this could
            generate potential security concerns with users.
            Index Terms—Videoconferencing security, Videoconferencing
            privacy, Zoom, Meet, Teams vulnerabilities`,
            "file": "/files/1.SVCC_2020_paper_Nicholas.pdf"
        },
        {
            "title": "A Secure Encapsulation Schemes based on Key Recovery System",
            "author": "Taehoon Kim, Wonbin Kim , Daehee Seo , Imyeong Lee",
            "link": "https://drive.google.com/file/d/1HpnPRtEBLquFYuryo6ymLG2n0MR3MIT-/view?usp=sharing",
            "abstract": `Users on the network use encryption to send and
            receive data securely. Since ciphertext can only be encrypted
            and decrypted by lawful users, third parties do not have the
            ability to know the content of the message. However, a secret
            key is required for encryption, and if the secret key is lost or
            corrupted, there is a problem that the encrypted text cannot
            be decrypted. Also, malicious use of this encryption will cause
            problems. If encryption is used maliciously, the government
            cannot prevent criminal activity. Because of this law enforcement
            agencies need support for lawful interception to decrypt criminals
            or suspects’ ciphertexts. We need a key recovery system that can
            safely recover these secret keys or decrypt messages for lawful
            interception. If key information used in ciphertext is required,
            this can be decrypted by a Key Recovery Field (KRF) for
            both the message and the decrypted key are recovered. Various
            security threats exist in key recovery systems. Such as forgery
            and alteration of KRF, single point of failure, inability to recover
            keys, and collusion attacks. To solve this, we propose two secure
            key recovery systems.`,
            "file": "/files/2.SVCC_2020_paper_Taehoon.pdf"
        },
        {
            "title": "Path Authentication Protocol: Based on a Lightweight MAC and a Nonlinear Filter Generator",
            "author": "Yuki Taketa , Yuta Kodera , Takuya Kusaka , Yasuyuki Nogami",
            "link": "https://drive.google.com/file/d/16Tg3j3YZr_XNzXFPAD_J6rdx2uEjyp5j/view?usp=sharing",
            "abstract": `In this research, the authors propose a functionality
            based on message authentication code (MAC) to ensure the
            correctness of its path from a source to a destination. It aims
            to use a controller area network (CAN) over driving system. In
            the construction, MAC and a pseudorandom number generator
            having an intentional group structure are employed so that
            the correct user can authenticate a message and its path. The
            proposed method is experimentally evaluated by observing the
            computational time over IoT devices. As a result, it is found that
            the results show that it is possible to implement as an additional
            function that gives path authentication with additional time at
            most 3 times for the corresponding computation.`,
            "file": "/files/3.SVCC_2020_paper_Yuki.pdf"
        },
        {
            "title": "STRISA: A New Regulation Architecture to Enforce Travel Rule",
            "author": "Wei-Tek Tsai, Dong Yang, Rong Wang, Kangmin Wang, Weijing Xiang, Enyan Deng",
            "link": "https://drive.google.com/file/d/1f_Cn6E4xcOWe-d0zJjQSkqKQGbRy4w3N/view?usp=sharing",
            "abstract": `FATF introduces a new regulation framework with
            Travel Rule in 2019, many crypto exchanges have incorporated
            the TRISA (Travel rule information sharing architecture) system as a part of their compliance mechanisms. However, the
            TRISA system may not meet the regulation requirements for all
            the countries. This paper proposes another version of TRISA,
            STRISA, that is compatible with the TRISA, but with four
            new features: 1) track individual digital wallets; 2) support a
            BDL (Blockchain Data Lake) platform that can interoperate
            with various blockchain (BC) systems for analysis; 3) supply
            a regulation enforcement framework using smart contracts with
            automated machine learning techniques; 4) Most of data are
            stored in associated BCs to ensure that no one including
            regulators should try to alter the data stored to protect the
            integrity of entire regulation compliance process. This paper
            also discusses other design and protocols to support these new
            features including unique storage scheme, double-interlocking
            mechanism. STRISA has been implemented and is available for
            research and experimentation.`,
            "file": "/files/4.SVCC_2020_paper_Wei-Tek.pdf"
        },
        {
            "title": "Post-Quantum Hash-Based Signatures for Secure Boot",
            "author": "Panos Kampanakis, Peter Panburana, Michael Curcio, Chirag Shroff",
            "link": "https://drive.google.com/file/d/1K3LA92i35kybV8OCE5goTVszbpo2jn2G/view?usp=sharing",
            "abstract": `The potential development of large-scale quantum
            computers is raising concerns among IT and security research
            professionals due to their ability to solve (elliptic curve) discrete
            logarithm and integer factorization problems in polynomial time.
            All currently used, public-key cryptography algorithms would
            be deemed insecure in a post-quantum setting. In response,
            the United States National Institute of Standards and Technology has initiated a process to standardize quantum-resistant
            cryptographic algorithms, focusing primarily on their security
            guarantees. Additionally, the Internet Engineering Task Force has
            published two quantum-secure signature schemes and has been
            looking into adding quantum-resistant algorithms in protocols. In
            this work, we investigate two post-quantum, hash-based signature
            schemes published by the Internet Engineering Task Force and
            submitted to the National Institute of Standards and Technology
            for use in secure boot. We evaluate various parameter sets for the
            use-cases in question and we prove that post-quantum signatures
            would not have material impact on image signing. We also study
            the hierarchical design of these signatures in different scenarios
            of hardware secure boot.`,
            "file": "/files/5.SVCC_2020_paper_Panos.pdf"
        },
        {
            "title": "Exploring the Coverage of Existing Hardware Vulnerabilities in Community Standards",
            "author": "Paul A. Wortman, Fatemeh Tehranipoor, John A. Chandy",
            "link": "https://drive.google.com/file/d/1S4lLZ-_2okBl1choPX3YaxPJi81TF1ZB/view?usp=sharing",
            "abstract": `Hardware security vulnerabilities have taken on
            greater importance over the last decade as academic and industry
            research has brought the issue to the forefront. The scope and
            breadth of these vulnerabilities have made them difficult to
            classify as has been done for software and network systems with
            the well-known Common Vulnerabilities and Exposures (CVE)
            database maintained by the MITRE Corporation. As further
            hardware security research continues, there is a requirement to
            standardize the language and references of academics, industry,
            and government alike. Without this common lexicon it becomes
            exceedingly difficult and potentially confusing when exchanging
            ideas, concepts, and methodologies across various sectors. Building and maintaining a glossary of terms, concepts, and concerns
            allows for a focus towards further research and development
            in the field of hardware security. In this paper, we review
            the nascent efforts by academia and industry in categorizing
            and documenting the existing hardware security landscape. The
            contributions of our work is an examination of the existing
            community efforts in the classification of hardware weakness with
            a specific focus on the CWE database. While current efforts are
            helping to contour the hardware landscape, similar to the work
            already performed for software and networks, the field is still
            evolving, and more work is required.`,
            "file": "/files/6.SVCC_2020_paper_Paul.pdf"
        },
        {
            "title": "MurQRI: Encrypted Multi-Layer QR Codes for Electronic Identity Management",
            "author": "Bonha Koo, Taegeun Moon, Hyoungshick Kim",
            "link": "https://drive.google.com/file/d/1L8OLzR8yJAkTU72WGqPmPEwwA5Y5PWbl/view?usp=sharing",
            "abstract": `Quick Response (QR) codes are widely used due to
            their versatility and low deployment cost. However, the existing
            QR code standard is ineffective for security-critical applications
            (e.g., electronic identity management) as the stored information
            can be easily exposed to unauthorized parties. Moreover, it does
            not provide sufficient storage capacity to employ robust encryption schemes for complex access control and authentication. In
            this paper, we present a novel approach of employing encrypted
            multi-layer QR codes, MurQRI (pronounced “Mercury”), for
            secure user authentication and fine-grained access control in
            various domains (e.g., airport and hospital). MurQRI is designed
            to store up to 45 kilobytes of data and protect the stored
            information via biometric authentication and encryption. To
            support fine-grained access control, we employ attribute-based
            encryption. We also introduce real-world applications where
            MurQRI can be used effectively and discuss possible methods
            to enhance security.`,
            "file": "/files/7.SVCC_2020_paper_Bonha.pdf"
        },


        {
            "title": "SpartanGold: A Blockchain for Education, Experimentation, and Rapid Prototyping",
            "author": "Thomas H. Austin",
            "link": "https://drive.google.com/file/d/1lHQKwX4qK--JCJwSLIMdLJd4WpUMuyzC/view?usp=sharing",
            "abstract": `Since the introduction of Bitcoin and the blockchain,
            the cryptocurrency space has seen rapid innovation in both
            academic research and in cutting edge designs from industry.
            Student interest has likewise increased rapidly.
            All of these areas would benefit from the ability to rapidly
            prototype ideas without becoming bogged down in complex code
            bases: academic researchers need a way to highlight key concepts
            of their designs; industry engineers could vet their early designs
            and identify potential challenges; and students would be able to
            play with key concepts of the blockchain in a simple, easy to
            understand codebase.
            
            This paper introduces SpartanGold, a blockchain implemen-
            tation written in JavaScript patterned after Bitcoin. Several
            features of Bitcoin’s design are simplified or eliminated, though
            those features may be added in easily. SpartanGold supports a
            single-threaded mode where miners communicate via JavaScript
            events, and a multi-process mode where miners and clients
            send messages over TCP/IP. While the former mode simplifies
            testing and can provide cleaner demonstrations, the latter mode
            provides a more realistic experience. We show the utility of
            SpartanGold by implementing different designs of mining pools
            and by showing how Bitcoin’s UTXO model could be added to
            SpartanGold.`,
            "file": "/files/8.SVCC_2020_paper_Thomas.pdf"
        },

        {
            "title": "BIOT: A blockchain-based IoT Platform for Distributed Energy Resource Management",
            "author": "Mostafa Yalpanian , Naser Mirzaei , Alireza Parvizimosaed, Farid Farmani, Mehdi Parvizimosaed, Behdad Bahrami ",
            "link": "https://drive.google.com/file/d/13wip7kMsKs87SH65yhcnnVLmKDaTo0io/view?usp=sharing",
            "abstract": `Smart grids are IoT-enabled grids at which communication devices can be used to interconnect, monitor and
            
            manage distributed energy resources (DERs). The emerging
            efficient and clean energy DERs such as Lithium-ion batteries
            and Photovoltaic have been widely used in IoT-enabled smart
            grids that makes their secure operation challenging. In this paper,
            a blockchain-based IoT platform, namely BIoT, is proposed to
            maintain security of energy supply network including DERs
            against IoT platform’s security issues, e.g., single point of
            failure, malicious code and data leak. BIoT verifies resource
            
            management requests in a consensus process, provides certificate-
            based authentication as well as attribute-based access control, and
            
            ensures data integrity. BIoT’s performance has been evaluated
            on Ontario Energy Board (OEB) Cybersecurity framework and
            a performance benchmark.`,
            "file": "/files/9.SVCC_2020_paper_Mostafa.pdf"
        },
        {
            "title": "A Privacy Preserving E-voting System based on Blockchain",
            "author": "Wenjun Fan†, Shubham Kumar, Vrushali Jadhav, Sang-Yoon Chang and Younghee Park",
            "link": "https://drive.google.com/file/d/12WBmz06cYjENBD5wwrGOIcaQg97soGpw/view?usp=sharing",
            "abstract": `An electronic voting system can enable greater
            democracy by allowing virtual and remote voting and facilitating
            
            greater participation. However, the e-voting systems have experienced allegations due to corruption and unfair practices including
            
            voter impersonation, fraud, or duplicate votes. This has caused
            a decline in transparency and faith in the electoral process,
            resulting in reduced participation of citizens in the democratic
            process. To address these challenges, this paper proposes a
            blockchain-based e-voting system to ensure the fairness of the
            electoral process and restore people’s faith while protecting the
            users’ privacy. First, we utilize face recognition technology to
            authenticate voters after extracting feature sets from images
            while users actively make actions. Second, we design a smart
            contract to secure voter information during the election. Our
            
            approach involves analyzing the limitations of the existing electoral voting system and applies the blockchain-based solution
            
            to the vulnerable aspects of the existing system. We implement
            our approach by using Ethereum, smart contract, and OpenCV
            Haar Cascade detection classifiers, and our evaluation based on
            the prototype shows that our approach is effective and efficient.`,
            "file": "/files/10.SVCC_2020_paper_Wenjun.pdf"
        },
        {
            "title": "Reconstructing Classification to Enhance Machine-Learning Based Network Intrusion Detection by Embracing Ambiguity",
            "author": "Chungsik Song, Wenjun Fan, Sang-Yoon Chang and Younghee Park",
            "link": "https://drive.google.com/file/d/1DjKaQBMJRpk9xFAveSrwo2XdfgFkipNb/view?usp=sharing",
            "abstract": `Network intrusion detection systems (IDS) has efficiently identified the profiles of normal network activities,
            
            extracted intrusion patterns, and constructed generalized models
            to evaluate (un)known attacks using a wide range of machine
            learning approaches. In spite of the effectiveness of machine
            learning-based IDS, it has been still challenging to reduce high
            false alarms due to data misclassification. In this paper, by using
            multiple decision mechanisms, we propose a new classification
            method to identify misclassified data and then to classify them
            into three different classes, called a malicious, benign, and
            ambiguous dataset. In other words, the ambiguous dataset
            contains a majority of the misclassified dataset and is thus the
            most informative for improving the model and anomaly detection
            because of the lack of confidence for the data classification in
            the model. We evaluate our approach with the recent real-world
            network traffic data, Kyoto2006+ datasets, and show that the
            ambiguous dataset contains 77.2% of the previously misclassified
            data. Re-evaluating the ambiguous dataset effectively reduces
            the false prediction rate with minimal overhead and improves
            accuracy by 15%.`,
            "file": "/files/11.SVCC_2020_paper_Chungsik.pdf"
        },
        {
            "title": "ConTheModel: Can we modify tweets to confuse classifier models",
            "author": "Aishwarya Ram Vinay, Mohsen Ali Alawami, Hyoungshick Kim",
            "link": "https://drive.google.com/file/d/1kvnp-bTelPvzDsXp4JuYV4xMoG4wClio/view?usp=sharing",
            "abstract": `News on social media can significantly influence
            users, manipulating them for political or economic reasons.
            Adversarial manipulations in the text have proven to create
            vulnerabilities in classifiers, and the current research is towards
            
            finding classifier models that are not susceptible to such manipulations. In this paper, we present a novel technique called
            
            ConTheModel, which slightly modifies social media news to
            
            confuse machine learning (ML)-based classifiers under the black-
            box setting. ConTheModel replaces a word in the original tweet
            
            with its synonym or antonym to generate tweets that confuse
            classifiers. We evaluate our technique on three different scenarios
            
            of the dataset and perform a comparison between five well known machine learning algorithms, which includes Support
            
            Vector Machine (SVM), Naive Bayes (NB), Random Forest
            (RF), eXtreme Gradient Boosting (XGBoost), and Multilayer
            Perceptron (MLP) to demonstrate the performance of classifiers
            on the modifications by ConTheModel. Our results show that
            the classifiers are confused after modification with the utmost
            drop of 16.36%. We additionally conducted a human study with
            25 participants to validate the effectiveness of ConTheModel and
            found that the majority of participants (65%) found it challenging
            to classify the tweets correctly. We hope our work will help in
            finding robust ML models against adversarial examples.`,
            "file": "/files/12.SVCC_2020_paper_Aishwarya.pdf"
        },
        {
            "title": "A Systematic Approach to Building Autoencoders for Intrusion Detection",
            "author": "Youngrok Song, Sangwon Hyun, Yun-Gyung Cheong",
            "link": "https://drive.google.com/file/d/19kFnH4bIC02oWSQW6XtXmPIrDOG39F0F/view?usp=sharing",
            "abstract": `Network Intrusion Detection Systems (NIDS) have
            been the most effective defense mechanism against various
            network attacks. As attack patterns have been intelligently
            and dynamically evolving, the deep learning-based NIDSs have
            been widely adopted to improve intrusion detection accuracy.
            Autoencoders, one of the unsupervised neural networks, are
            generative deep learning models that learn to represent the data
            as compressed vectors without class labels. Recently, various
            autoencoders–generative deep learning models–have been used
            for NIDS in order to efficiently alleviate the laborious labeling
            
            and to effectively detect unknown types of attacks (i.e. zero-
            day attacks). In spite of the effectiveness of autoencoders in
            
            detecting intrusions, it requires tremendous effort to identify
            the optimal model architecture of the autoencoders that results
            in the best performance, which is an obstacle for practical
            applications. To address this challenge, this paper rigorously
            studies autoencoders with two important factors using real
            network data. We investigate how the size of a latent layer and
            the size of the model influence the detection performance. We
            evaluate our autoencoder model using the IDS benchmark data
            sets and present the experimental findings.`,
            "file": "/files/13.SVCC_2020_paper_Youngrok.pdf"
        },


        {
            "title": "Poster: An Attack on Quantum Circuits Based on the Error Rates of NISQ Systems and a Countermeasure",
            "author": "Nikita Acharya , Vedika Saravanan , Samah Mohamed Saeed",
            "link": "https://drive.google.com/file/d/1nTLY4iYUMtGwEedoOkVEZCsopI2SpPSi/view?usp=sharing",
            "abstract": `Noisy Intermediate Scale Quantum (NISQ) computers are subject to different sources of noise. Noise-aware quantum
            
            compilers enhance the reliability of quantum computers. A
            quantum compiler plays a critical role in mapping the quantum
            circuit to a physical circuit that can be executed on the quantum
            hardware. A malicious compiler can launch several attacks to
            increase the error rates in the circuit, and thus, corrupt the circuit
            output. To detect these attacks, we utilize circuit test points, which
            provide the meta-information of the circuit errors and infer the
            reliability of the circuit output.`,
            "file": "/files/17.SVCC_2020_poster_Nikita.pdf"
        },
        {
            "title": "POSTER: Cryptocurrency with Multiple Sequence Alignment Proof-of-Work",
            "author": "Yan Chen",
            "link": "https://drive.google.com/file/d/18LrBVDJyI1bCZY_ZxVnjVsZxrjXYZySd/view?usp=sharing",
            "abstract": `Although Bitcoin is a successful electronic cash
            system, mining Bitcoins wastes computation resources since the
            proof-of-work requires the miners to solve useless computational
            puzzles. On the other hand, multiple sequence alignment is widely
            used in bioinformatics for analyzing similarities or differences
            among sequences, but it often takes time and computational
            efforts. To reduce the waste of mining Bitcoins, this paper
            proposes a modification to Bitcoin protocol so that it requires the
            miners to find a multiple sequence alignment of protein sequences
            for proof-or-work.`,
            "file": "/files/15.SVCC_2020_paper_Yan.pdf"
        },
        {
            "title": "Poster: IoT Checker Using Timing Side-channel and Machine Learning",
            "author": "Kratika Sahu, Rasika Kshirsagar, Surbhi Vasudeva, Taghreed Alzahrani, and Nima Karimian",
            "link": "https://drive.google.com/file/d/1YnbMXUIMwVQO5e0lgmoBtK2PtsvU3nLF/view?usp=sharing",
            "abstract": `In the recent era, adoption of IoT technology can
            be seen in nearly every other field. However, with its fast growth
            rate, the IoT brings several advanced security challenges. To
            alleviate these problems, we propose an inventive framework
            
            that could be introduced into the IoT package to gather side 
            channel information (execution timing) for inconsistency and
            
            attack location. We observed that the applications running on
            the gadget would need more time to execute during the attack
            as the resources such as memory, I/O, CPU will get drained.
            This timing information under normal and attack mode will be
            extracted, processed, and utilized to construct a fingerprint. The
            support vector machine method will then be used to identify if
            the device is under attack utilizing the fingerprint.`,
            "file": "/files/14.SVCC_2020_poster_Kratika.pdf"
        },
        {
            "title": "Poster: New Locked Circuits for Hardware Security",
            "author": "Michael Yue and Fatemeh Tehranipoor",
            "link": "https://drive.google.com/file/d/11NOYLGMfJ6SSQpLlIB2YuZ1S5JTfa2Hg/view?usp=sharing",
            "abstract": `Integrated circuit (IC) piracy and overproduction
            are serious issues that threaten the security of a system. Logic
            locking is a type of hardware obfuscation technique where
            additional key gates are inserted into the circuit. Only the correct
            key can unlock the functionality of that circuit otherwise the
            system produces the wrong output. In an effort to hinder these
            threats on ICs, we have developed a probability-based logic
            locking technique to protect the design of a circuit. We have
            tested our algorithm on 40 benchmarks from the ISCAS ’85 and
            ISCAS ’89 suite.`,
            "file": "/files/18.SVCC2020_poster_Michael.pdf"
        },
        {
            "title": "Poster: Deep Learning for Side-Channel Analysis",
            "author": "Soroor Ghandali and Fatemeh Tehranipoor",
            "link": "https://drive.google.com/file/d/1cV2AK_7fP3O3Sb9UyBVT2VX-1mmL52to/view?usp=sharing",
            "abstract": `In a recent line of works, several strong power

            analysis techniques for attacking secure hardware implementations have been proposed. Deep learning profiling techniques
            
            leads to strong power analysis techniques for retrieve the secret
            information. However, evaluation of security still requires more
            powerful profiled side-channel attacks since the leakage models
            are more secure with different masking schemes. We propose a
            
            novel model using Convolutional Neural Networks (CNNs) to exploit the power consumption of complex cryptographic functions.
            
            The proposed deep model includes 256 class and its learning
            procedure is directly from the power side-channel leakges of
            an AES-128. Experimental results show the effectiveness of our
            model which achieved a success rate of 99% even with a single
            trace and correct key rank of ≤ 10.`,
            "file": "/files/16.SVCC2020_poster_Soroor.pdf"
        },


    ]
    let { paper } = useParams();
    let displayPaper = []
    console.log(paper)
    console.log(paper.split("-").join(" "))
    if (paper) {
        displayPaper = papers.filter(f => f.title.split(" ").join("-") === paper);
        if (displayPaper.length > 0) displayPaper = displayPaper[0]
    }
    console.log(displayPaper)
    const [activeDurationIndex, setActiveDurationIndex] = useState(0);
    // const currAgenda = agenda[activeDurationIndex];
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    return (
        <AnimationRevealPage>
            <Header />
            <Container style={{ textAlign: "justify", textJustify: "interWord" }}>
                <ContentWithPaddingXl>
                    <div className="py-6">
                        <h1 style={{ "color": "#6415FF", fontSize: "2rem", fontWeight: "700", textAlign: "center", marginTop: "0px", marginLeft: "0px" }}>
                            {displayPaper.title}
                        </h1>
                    </div>
                    <div className="py-1">
                        <h1 style={{ fontSize: "1.4rem", fontWeight: "700", textAlign: "center", marginTop: "0px", marginLeft: "0px" }}>
                            {displayPaper.author}
                        </h1>
                    </div>
                    <div className="pt-6">
                        <h1 style={{ fontSize: "1rem", fontWeight: "500", textAlign: "justify", marginTop: "0px", marginLeft: "0px" }}>
                            {displayPaper.abstract}
                        </h1>
                    </div>
                    <div className="py-8">
                        {/* <embed src={displayPaper.link} width="800px" height="2100px" /> */}
                        {/* <iframe src={displayPaper.link}  style={{"width":"600px", "height":"500px"}} frameborder="0"></iframe> */}
                        <object width="100%" height="800" data={displayPaper.file} type="application/pdf" >   </object>
                       
                    </div>
                </ContentWithPaddingXl>
            </Container>
            <Footer />
        </AnimationRevealPage>
    );
};
