import React, { useState } from "react";
import tw from "twin.macro";
import Header from "../../components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import Tabs from "./Tab";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl py-10 md:py-12 py-20 md:pb-4`;
const LeftColumn = tw.div`relative lg:w-4/12 lg:pr-12 flex-shrink-0 text-center lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:w-8/12 lg:mt-0 flex flex-col justify-center border-b border-black`;
export default ({
}) => {
    return (
        <div>
            <AnimationRevealPage>
                <Header />
                <Tabs />
                {/* <Container >
                    <TwoColumn className="mx-40">
                        <LeftColumn>
                            08:00 am to 09:00 am
                        </LeftColumn>
                        <RightColumn>
                            b
                        </RightColumn>
                    </TwoColumn>
                </Container> */}
                <Footer />
            </AnimationRevealPage>
        </div>
    );
};
