import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings";
import { SectionDescription } from "components/misc/Typography";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as LinkedinIcon } from "images/linkedin-icon.svg";
import { ReactComponent as GithubIcon } from "images/github-icon.svg";
import { LinkedinFilled } from '@ant-design/icons';
const HeadingContainer = tw.div``
const Heading = tw(SectionHeading)``
const Subheading = tw(SubheadingBase)`text-center mb-3`
const Description = tw(SectionDescription)`mx-auto text-center`

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto`
const Card = tw.div`mt-24 w-full sm:w-1/2 lg:w-1/2 flex flex-col items-center`
const CardImage = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`w-64 h-64 bg-contain bg-center rounded`}
`
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-500`}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  9
`

const CardLinks = styled.div`
  ${tw`mt-6 flex`}
  .link {
    ${tw`mr-8 last:mr-0 text-gray-400 hocus:text-primary-500 transition duration-300`}
    .icon {
      ${tw`fill-current w-6 h-6`}
    }
  }
`

export default ({
    heading = "Distinguished Speakers.",
    subheading = "Our",
    description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    cards = [
        {
            imageSrc: "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&fit=facearea&facepad=2.95&w=512&h=512&q=80",
            position: "Founder",
            name: "Adam Cuppy",
            links: [
                {
                    url: "https://twitter.com",
                    icon: TwitterIcon,
                },
                {
                    url: "https://linkedin.com",
                    icon: LinkedinIcon,
                },
                {
                    url: "https://github.com",
                    icon: GithubIcon,
                },
            ],
        },
        {
            imageSrc: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&fit=facearea&facepad=2.95&w=512&h=512&q=80",
            position: "Sr. Designer",
            name: "Charlotte Hale",
            links: [
                {
                    url: "https://twitter.com",
                    icon: TwitterIcon,
                },
                {
                    url: "https://linkedin.com",
                    icon: LinkedinIcon,
                },
                {
                    url: "https://github.com",
                    icon: GithubIcon,
                },
            ],
        },
        // {
        //   imageSrc: "https://images.unsplash.com/photo-1517070208541-6ddc4d3efbcb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&fit=facearea&facepad=2.95&w=512&h=512&q=80",
        //   position: "Jr. Designer",
        //   name: "Silvester Wize",
        //   links: [
        //     {
        //       url: "https://twitter.com",
        //       icon: TwitterIcon,
        //     },
        //     {
        //       url: "https://linkedin.com",
        //       icon: LinkedinIcon,
        //     },
        //     {
        //       url: "https://github.com",
        //       icon: GithubIcon,
        //     },
        //   ],
        // },
        // {
        //   imageSrc: "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&fit=facearea&facepad=2.95&w=512&h=512&q=80",
        //   position: "Lead Developer",
        //   name: "Himali Turn",
        //   links: [
        //     {
        //       url: "https://twitter.com",
        //       icon: TwitterIcon,
        //     },
        //     {
        //       url: "https://linkedin.com",
        //       icon: LinkedinIcon,
        //     },
        //     {
        //       url: "https://github.com",
        //       icon: GithubIcon,
        //     },
        //   ],
        // },
        // {
        //   imageSrc: "https://images.unsplash.com/photo-1546820389-44d77e1f3b31?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&fit=facearea&facepad=3.45&w=512&h=512&q=80",
        //   position: "Sr. Developer",
        //   name: "Troye Sivan",
        //   links: [
        //     {
        //       url: "https://twitter.com",
        //       icon: TwitterIcon,
        //     },
        //     {
        //       url: "https://linkedin.com",
        //       icon: LinkedinIcon,
        //     },
        //     {
        //       url: "https://github.com",
        //       icon: GithubIcon,
        //     },
        //   ],
        // },
        // {
        //   imageSrc: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&fit=facearea&facepad=3.45&w=512&h=512&q=80",
        //   position: "Quality Assurance",
        //   name: "Holo Wo",
        //   links: [
        //     {
        //       url: "https://twitter.com",
        //       icon: TwitterIcon,
        //     },
        //     {
        //       url: "https://linkedin.com",
        //       icon: LinkedinIcon,
        //     },
        //     {
        //       url: "https://github.com",
        //       icon: GithubIcon,
        //     },
        //   ],
        // },
    ]
}) => {
    return (
        <Container>
            <ContentWithPaddingXl>
                <HeadingContainer>
                    {/* {subheading && <Subheading>{subheading}</Subheading>} */}
                    {heading && <Heading>{heading}</Heading>}
                    {/* {description && <Description>{description}</Description> } */}
                </HeadingContainer>
                <Cards>
                    {/* {cards.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.imageSrc} />
              <CardContent>
                <span className="position">{card.position}</span>
                <span className="name">{card.name}</span>
                <CardLinks>
                  {card.links.map((link, linkIndex) => (
                    <a key={linkIndex} className="link" href={link.url}>
                      <link.icon className="icon" />
                    </a>
                  ))}
                </CardLinks>
              </CardContent>
            </Card>
          ))} */}
          <Card>
                    <div class="flex flex-col items-center justify-center bg-white p-4 shadow rounded-lg">

                        <div class="inline-flex shadow-lg border border-gray-200 rounded-full overflow-hidden h-40 w-40 speaker-border">

                            <img src="https://images.squarespace-cdn.com/content/v1/5e67cd61d3d039551d9349d2/1599845712860-NJ1Y4WEBUUC8X3DASQMI/ke17ZwdGBToddI8pDm48kNhLVqbMSQe1LATz2gm9H5ZZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7XRK3dMEBRBhUpwuIcb__zrC4pJLHjKaxKSQJ74OELJkLV05aabR_pTgiF7QgOruKFXA9EXPXFMlomY/MGuel_Pic.jpg?format=500w"
                                alt=""
                                class="h-full w-full"
                            />
                        </div>

                        <h2 class="mt-4 font-bold text-2xl">Michele D. Guel</h2>
                        {/* <p class="text-xs text-gray-500 text-center mt-3">
    <PlusCircleTwoTone onClick={() => this.handleModal(true, 0)} />
</p> */}
                        <p class="text-xs text-gray-500 text-center mt-3">
                            Michele D. Guel has been an avid speaker, influencer and evangelist in the cybersecurity industry for 31 years. She joined Cisco in March 1996 as the founding member of Cisco’s internal security team. During her 24+ years at Cisco, she has worked in all facets of data, information, network security, IoT operational security excellence and has established many “firsts” at Cisco. In her current role in the Office of the CTO for the Security Business Group, she is focused on data security and privacy strategies for Cisco products and offerings. In 2010, Michele was promoted to Distinguished Engineer (DE), one of 9 female DEs across Cisco today. In 2014 she co-founded Cisco’s Women in Cybersecurity Community which focuses on developing the next generation of women cybersecurity leaders. In 2016 she received the prestigious Anita Borg’s 2016 Women of Vision Technology Leadership Award.
</p>
                        <ul class="flex flex-row mt-4 space-x-2">
                            <li>
                                <a href="" class="flex items-center justify-center h-8 w-8 border rounded-full text-gray-800 border-gray-800">
                                    <LinkedinFilled style={{ fontSize: '16px', color: '#08c' }} />
                                </a>
                            </li>
                        </ul>
                    </div>
                    </Card><Card>
                    <div class="flex flex-col items-center justify-center bg-white p-4 shadow rounded-lg">
                        <div class="inline-flex shadow-lg border border-gray-200 rounded-full overflow-hidden h-40 w-40 speaker-border">
                            <img src="https://images.squarespace-cdn.com/content/v1/5e67cd61d3d039551d9349d2/1599845677650-97PD91WTW9PIP78J8TI8/ke17ZwdGBToddI8pDm48kM9dCTH6_dwnO5G0WDer2d1Zw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7XRK3dMEBRBhUpyhfwEu9olAVXLG3Z-x93CBfy4FkWxLyF7cOcfmS_Duxt1tWRUsbfLR12nGZfh1cfY/Melike_GB.jpg?format=500w"
                                alt=""
                                class="h-full w-full" />
                        </div>
                        <h2 class="mt-4 font-bold text-2xl">Melike Erol-Kantarci</h2>
                        <p class="text-xs text-gray-500 text-center mt-3">
                            Melike Erol-Kantarci is Tier 2 Canada Research Chair in AI-enabled Next-Generation Wireless Networks and associate professor at the School of Electrical Engineering and Computer Science at the University of Ottawa. She is the founding director of the Networked Systems and Communications Research (NETCORE) laboratory. She has over 140 peer-reviewed publications which have been cited over 5000 times and she has an h-index of 36. She has acted as the general chair and technical program chair for many international conferences and workshops; most recently TPC Chair for IEEE CAMAD 2020, track chair for IEEE Globecom 2020, IEEE SmartGridComm 2020 and IEEE CCNC 2021. Her main research interests are AI-enabled wireless networks, 5G and 6G wireless communications, smart grid, electric vehicles, Internet of things and cybersecurity. She is a senior member of the IEEE.
</p>
                        <ul class="flex flex-row mt-4 space-x-2">
                            <li>
                                <a href="" class="flex items-center justify-center h-8 w-8 border rounded-full text-gray-800 border-gray-800">
                                    <LinkedinFilled style={{ fontSize: '16px', color: '#08c' }} />
                                </a>
                            </li>
                        </ul>
                    </div>
                    </Card>
                </Cards>
            </ContentWithPaddingXl>
        </Container>
    );
};
