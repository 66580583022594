import { ReactComponent as LinkedinIcon } from "images/linkedin-icon.svg";
import { ReactComponent as WebsiteIcon } from "images/website-icon.svg";
const keyNoteSpeakers = [{
    imageSrc: "http://www.cs.cornell.edu/people/egs/pics/egs-headshot-small.jpg",
    position: "Professor of computer science at Cornell University",
    name: "Emin Gun Sirer",
    profileLink: "emin-gun-sirer",
    description: "Emin Gun Sirer is a professor of computer science at Cornell University, founder of Ava Labs, co-founder of bloXroute Inc, and co-director of the Initiative for Cryptocurrencies and Smart Contracts (IC3). Among other things, Sirer is known for having implemented the first currency that used proof of work to mint coins, for selfish mining, for characterizing the scale and centralization of existing cryptocurrencies, as well as having proposed the leading protocols for on-chain and off-chain scaling. Of all his collaborations, he is proudest of his contributions to the John Oliver Show's segment on cryptocurrencies.",
    links: [
        // {
        //    url : "https://www.linkedin.com/in/ninataft/",
        //    icon : LinkedinIcon
        // }
    ],
    talks: [{
        title: "Open and Programmable Finance with Avalanche",
        abstract: "Before Ethereum, many would have scoffed at the very notion of decentralized finance. Now, it's one of the most hyped areas of fintech. Ava Labs is building upon that momentum with a breakthrough in consensus protocols – Avalanche – to usher in a new era of finance defined by velocity, efficient use of capital, security against bad actors, and preservation of network value. This talk will focus on the Avalanche network and the Internet of Finance being built upon it."
    }]
}, {
    imageSrc: "/img/chris-romeo.png",
    position: "CEO, Security Journey, Former & Former Chief Security Advocate at Cisco",
    name: "Chris Romeo",
    profileLink: "chris-romeo",
    description: "Chris Romeo is CEO and co-founder of Security Journey, building security culture influencing education.His passion is to bring security culture change to all organizations, large and small, by creating anddesigning gamified security programs. Chris is a highly rated industry speaker and trainer, featured atRSA Conference, OWASP Global AppSec, and ISC2 Security Congress. Chris was the Chief SecurityAdvocate at Cisco for five years, empowering engineers to shift security left in all products at Cisco andled Cisco’s security belt program (Cisco Security Ninja). Chris has twenty-three years of securityexperience, holding positions across the gamut, including application security, security engineering, andincident response. Chris holds the CISSP and CSSLP certifications.",
    links: [
        {
            url: "https://www.linkedin.com/in//securityjourney/",
            icon: LinkedinIcon
        }
    ],
    talks: [{
        title: "Application Security in a Layer Seven World",
        abstract: "The world of security has morphed over the last twenty years, transitioning from securing the network and core to a world where applications are in the spotlight. Vulnerabilities are rampant within the modern-day application, spanning web, mobile, IoT, and API. If we have applications, then we must have application security.In this talk, Chris shares a decade of experience focusing on application security, sharing application security challenges/trends/future from an academic and industry perspective. You'll appreciate the size and scope of the security problems that applications introduce and explore the modern-day application security toolbox to solve these problems, including SDL, SAST, DAST, and SCA, Docker, and Kubernetes."
    }]
},
{
    imageSrc: "/img/bhunia-swarup.png",
    position: "Director, Warren B. Nelms Institute SEMMOTO ENDOWED PROFESSOR, University of Florida",
    name: "Bhunia,Swarup",
    profileLink: "bhunia-swarup",
    description: "Swarup Bhunia is the Semmoto Endowed Professor and Director of the Warren B. Nelms Institute for the Connected World in the University of Florida, FL, USA. Earlier he was appointed as the T. and A. Schroeder associate professor of Electrical Engineering and Computer Science at Case Western Reserve University, Cleveland, OH, USA. He has over 20 years of    research and development experience with over 200 publications in peer-reviewed journals and    premier conferences. His research interests include hardware security and trust, adaptive    nanocomputing and novel test methodologies. Dr. Bhunia received IBM Faculty Award (2013),    National Science Foundation career development award (2011), Semiconductor Research    Corporation Inventor Recognition Award (2009), and SRC technical excellence award as a team    member (2005), and several best paper awards/nominations. He has been serving as an associate    editor of IEEE Transactions on CAD, IEEE Transactions on Multi-Scale Computing Systems,    ACM Journal of Emerging Technologies; served as guest editor of IEEE Design & Test of    Computers (2010, 2013) and IEEE Journal on Emerging and Selected Topics in Circuits and    Systems (2014). He has served in the organizing and program committee of many IEEE/ACM    conferences. He is a senior member of IEEE. He received his B.E. (Hons.) from Jadavpur    University, Kolkata, India, M.Tech. from the Indian Institute of Technology (IIT), Kharagpur,    and Ph.D. from Purdue University, IN, USA.",
    links: [
        {
            url: "http://swarup.ece.ufl.edu/",
            icon: WebsiteIcon
        }
    ],
    talks: [{
        title: "Quantifiable Assurance of Microelectronics: The Verification Perspective",
        abstract: "In the world we live today, security verification of “things” around us – small and large – electronics and beyond – have become serious concerns for manufacturers and consumers alike. On one hand, security has become a critical design challenge for modern electronic hardware. With the emergence of the Internet of Things (IoT) regime that promises exciting new applications from smart cities to connected autonomous vehicles, security has come to the forefront of the system design process. Recent discoveries and reports on numerous security attacks on microchips and circuits violate the well-regarded concept of hardware trust anchors. It has prompted system designers to develop a wide array of verification solutions to achieve high-security assurance for electronic hardware, which supports the software stack. Security issues are only going to grow and become more important in the next decades due to the changing nature of the supply chain, e.g. globally distributed design/manufacturing that involves multiple untrusted parties, and online distribution. In this talk, I will outline the need and challenges for verification for security and point to some promising solutions. In particular, I will cover the verification of hardware intellectual properties (IPs), microchips, and electronics systems and depict some promising solutions, e.g., ones that build on machine learning and formal analysis."
    }]

},
{
    imageSrc: "https://storage.googleapis.com/pub-tools-public-publication-data/png/6f6f5d38b876bc30a695b1cd532572395fec8319.png",
    position: "Senior Staff Research Scientist at Google",
    name: "Nina Taft",
    profileLink: "nina-traft",
    description: "Nina Taft is a Senior Staff Research Scientist at Google where she leads the Applied Privacy Research group. For many years, Nina worked in the field of networking, focused on traffic modeling and analysis, protocols, and intrusion detection. She is well known for her work in statistical modeling of city-to-city internet traffic patterns and was chosen as one of the “top-10 women in networking in 2017” (ACM’s N2Women award). Her current interests lie in applications of machine learning for privacy, private data analytics, and user experience.  Nina received her Ph.D. from UC Berkeley, and has worked in industrial research labs since then - at SRI, Sprint Labs, Intel Berkeley Labs, Technicolor Research, and now at Google. She is a member of the Scientific Advisory Board for the Max Planck Institute in Germany, has been PC chair of ACM SIGCOMM and IMC, and serves on many program committees for academic conferences.",
    links: [
        {
            url: "https://www.linkedin.com/in/ninataft/",
            icon: LinkedinIcon
        }
    ],
    talks: [{
        title: "Approaches to Assist App Developers with Privacy",
        abstract: "Smartphone app developers face numerous challenges in improving their privacy posture that stem from an evolving world in terms of laws, policy, and users’ privacy preferences. In this talk, we summarize some of the latest approaches and technical advances to help Smartphone app developers improve their privacy posture. We start by summarizing some of the challenges developers face, how their users make decisions about privacy choices, and how users may influence developer design. We discuss examples of nudges that can incentivize developers to be more privacy-friendly. Considerable progress has been achieved recently in using Natural Language Processing techniques to automatically analyze privacy policies, and this lays the groundwork for useful tools. We show how this can assist developers by providing automated feedback, especially on critical issues such as how well an apps’ behavior adheres to its privacy policy."
    }]
},
{
    imageSrc: "/img/qiong-jo-zhang.png",
    position: "Research Scientist at Fujitsu Network Communications",
    name: "Qiong (Jo) Zhang",
    profileLink: "qiong-jo-zhang",
    description: "Qiong (Jo) Zhang is a Research Scientist at Fujitsu Network Communications. Her current research interests include blockchain, optical networks, network design and modeling, network control and management, and software-defined networking. Prior to joining FLA, she was an Assistant Professor in the Department of Mathematical Sciences and Applied Computing at Arizona State University. She received the M.S. and Ph.D. degrees from the University of Texas at Dallas, Richardson, TX, USA, in 2000 and 2005, respectively. She worked at the Fujitsu Laboratories of America from 2008 to 2020, and joined Fujitsu Network Communications in 2020. She was a recipient of the Best Paper Award of IEEE GLOBECOM 2005, ONDM 2010, IEEE ICC 2011, and IEEE NetSoft 2016 for her co-authored papers. She has served as technical program co-chair for IEEE NFV-SDN Conference as well as on technical program committee of Optical Fiber Communications Conference. ",
    links: [
        {
            url: "https://www.linkedin.com/in/qiong-zhang-3b90006b/",
            icon: LinkedinIcon
        }
    ],
    talks: [{
        title: "Cross-organizational blockchain technology for privacy-preserving federated learning",
        abstract: "With the ongoing digital transformation, large amounts of data are being generated by individuals, organizations, and IoT systems. Due to security and privacy concerns, currently these data are siloed, even though there is a strong motivation to utilize data across organizations to obtain valuable data-driven insights. To enable such data utilization, we have developed a new system for secure data exchange between organizations in a consortium with access control using blockchain. In this system, the data providers can register metadata along with access control policy associated with the actual data. This metadata and access control is recorded on the blockchain, while the actual data remains off-chain and stored locally. The data consumers can discover the different data available in the consortium based on the metadata and request for secure data transfer. We further extend this system to realize a blockchain based protocol for privacy-preserving federated learning by training local AI models with distributed on-premises data at each organization and exchanging only AI models. The proposed protocol tracks each federated learning step on the blockchain and takes advantage of consensus on blockchain to efficiently and securely transfer AI models between organizations, which simplifies the construction of a secure distributed platform for federated learning."
    }]
},]

const otherSpeakers = [{
    imageSrc: "/img/malek.jpeg",
    position: "Technology executive and the Americas Security R&D lead for Accenture.",
    name: "Malek Ben Salem",
    profileLink: "malek-ben-salem",
    description: "Dr. Malek BEN SALEM is a technology executive and the Americas Security R&amp;D lead forAccenture. She works on building innovative AI and security technologies helping organizationsreduce their risk and to proactively adhere to digital ethics principles in order to earn clients’trust—going beyond a compliance-driven security approach. Her research covers AI and IoTsecurity, Trustworthy AI, data protection, security analytics, and cloud and mobile security. Dr. BenSalem holds a PhD and a MSc in Computer Science from Columbia University, New York, a Dipl.-Ing. in Electrical Engineering from the Technical University of Hanover, GERMANY, and a certificatein Technology Strategy from the MIT Sloan School of Business.",
    links: [
        {
            url: "https://www.linkedin.com/in/malek-ben-salem/",
            icon: LinkedinIcon
        }
    ]

},
{
    imageSrc: "/img/judith-furlong.jpg",
    position: "Distinguished Engineer focused on security technology and strategy in the Dell Technologies Chief Technology Office",
    name: "Judy Furlong",
    profileLink: "judith-furlong",
    description: "Judy Furlong is a Distinguished Engineer focused on security technology and strategy in the Dell Technologies Chief Technology Office. She is passionate about defining and enabling a consistent anddifferentiating security capabilities across the Dell Technologies portfolio. Judy co-chairs the OASIS KeyManagement and Interoperability Protocol (KMIP) Technical Committee and is a contributor to securityactivities within OASIS, CSA, SAFECode and SNIA. Judy holds a MS and a BS in Electrical Engineeringfrom the Massachusetts Institute of Technology (MIT).",
    links: [
        {
            url: "https://www.linkedin.com/in/judithfurlong/",
            icon: LinkedinIcon
        }
    ]
},
// {
//     imageSrc: "/img/sara.jpg",
//     position: "Assistant Professor, Department of Electrical & Computer Engineering at the Santa Clara University (SCU)",
//     name: "Sara Tehranipoor",
//     profileLink: "sara-tehranipoor",
//     description : "Sara Tehranipoor is am an Assistant Professor of the Department of Electrical andComputer Engineering at the Santa Clara University (SCU), joined Fall 2019. Previously, Iwas an Assistant Professor of Engineering at the San Francisco State University for twoyears from 2017 to 2019, and awarded my Ph.D. in Electrical and Computer Engineeringfrom the University of Connecticut, in July 2017. My research interests include HardwareSecurity, Internet-of-Things (IoT) Security, and Embedded Systems. I received the “BestTechnical Paper Award” in the 30th International Conference on VLSI Design (VLSID) &amp;16th International Conference on Embedded Systems. I am currently serving as anassociate editor for IEEE Consumer Electronics Magazine.",
//     links : [
//         {
//            url : "http://tehranipoor.com/",
//            icon : WebsiteIcon
//         }
//     ]
// },
{
    imageSrc: "/img/jorjeta-jetcheva.jpg",
    position: " Assistant Professor, Computer Engineering Department at San José State University",
    name: "Jorjeta Jetcheva",
    profileLink: "jorjeta-jetcheva",
    description: "Jorjeta Jetcheva is an Assistant Professor in the Computer Engineering Department atSan José State University. Her current areas of research focus are Artificial Intelligence-based Personal Assistants, Natural Language Processing, and Knowledge Management.Prior to San Jose State, she was the global R&amp;D lead for the Virtual Assistant portfolioof Accenture Operations, where she was responsible for leading a global teamconceptualizing, building and deploying Virtual Assistants, AI-Powered KnowledgePlatforms, and Agent Assist solutions. Prior to Accenture, she was a Research Scientistand Manager at Fujitsu Laboratories of America (FLA), a Principal Engineer in Itron&#39;sSmart Grid Architecture and Standards team, and the Systems Architect of Bay Areastartup Firetide, covering a broad range of technology areas including Virtual andRobotic Assistants, Smart Grid analytics and applications, wireless and mobilenetworking, and cybersecurity. Professor Jetcheva received a Ph.D. degree in Computer Science from Carnegie MellonUniversity, and a B.A. degree with a double major in Computer Science (summa cumlaude) and Mathematics from Mount Holyoke College.",
    links: [
        {
            url: "https://www.linkedin.com/in/jorjetajetcheva/",
            icon: LinkedinIcon
        }
    ]
}]


const careerPanelists = [{
    imageSrc: "/img/melike-erol-kantarci.jpg",
    position: "Canada Research Chair in AI-Enabled Next-Generation Wireless Networks Associate Professor, School of Electrical Engineering and Computer Science , University of Ottawa",
    name: "Melike Erol-Kantarci",
    profileLink: "melike-erol-kantarci",
    description: "Melike Erol-Kantarci is Tier 2 Canada Research Chair in AI-enabled Next-Generation WirelessNetworks and associate professor at the School of Electrical Engineering and Computer Science atthe University of Ottawa. She is the founding director of the Networked Systems andCommunications Research (NETCORE) laboratory. She has over 140 peer-reviewed publicationswhich have been cited over 5000 times and she has an h-index of 36. Recently, she was selectedfor the 2019 list of “N2Women: Stars in Computer Networking and Communications” along with 7other distinguished scientists. She has received several best paper awards including the IEEECommunication Society Best Tutorial Paper Award in 2017. Dr. Erol-Kantarci is the co-editor of threebooks on smart grids, smart cities and intelligent transportation. She has delivered 40+ plenary talks,tutorials and seminars around the globe. She is the Specialty Chief Editor for the Smart GridCommunications Section of the journal “Frontiers in Communications and Networking”. She is on theeditorial board of the IEEE Transactions on Cognitive Communications and Networking, IEEEInternet of Things Journal, IEEE Communications Letters, IEEE Vehicular Technology Magazine andIEEE Access. She has acted as the general chair and technical program chair for many internationalconferences and workshops; most recently TPC Chair for IEEE CAMAD 2020, track chair for IEEEGlobecom 2020, IEEE SmartGridComm 2020 and IEEE CCNC 2021. Her main research interestsare AI-enabled wireless networks, 5G and 6G wireless communications, smart grid, electric vehicles,Internet of things and cybersecurity. She is a senior member of the IEEE.",
    links: [
        {
            url: "http://www.site.uottawa.ca/~merolka2/",
            icon: WebsiteIcon
        }
    ],
    talks: [{
        title: "Security in the Era of Hyper Connected World",
        abstract: "The world is transforming into a hyper connected place where a simple smartwatch can connect to the most sophisticated device in critical infrastructure in a few hops. Likewise, humans, cars, factories are becoming a part of a connected ecosystem where security becomes more important than ever. Building hard fortresses of security is becoming more challenging too. In this talk, we will focus on secure access being an integrated part of the hyper connectivity paradigm. We will present how AI can help with seamless authentication."
    }]
},
{
    imageSrc: "https://images.squarespace-cdn.com/content/v1/5e67cd61d3d039551d9349d2/1599845712860-NJ1Y4WEBUUC8X3DASQMI/ke17ZwdGBToddI8pDm48kNhLVqbMSQe1LATz2gm9H5ZZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7XRK3dMEBRBhUpwuIcb__zrC4pJLHjKaxKSQJ74OELJkLV05aabR_pTgiF7QgOruKFXA9EXPXFMlomY/MGuel_Pic.jpg",
    position: " Distinguished Engineer & Data Security and Privacy Strategist at Cisco",
    name: "Michele D. Guel",
    profileLink: "michele-d-guel",
    description: "Michele D. Guel has been an avid speaker, influencer and evangelist in the cybersecurity industry for 31years. She joined Cisco in March 1996 as the founding member of Cisco’s internal security team. Duringher 24+ years at Cisco, she has worked in all facets of data, information, network security, IoT operationalsecurity excellence and has established many “firsts” at Cisco. In her current role in the Office of the CTOfor the Security Business Group, she is focused on data security and privacy strategies for Cisco productsand offerings. In 2010, Michele was promoted to Distinguished Engineer (DE), one of 9 female DEsacross Cisco today. In 2014 she co-founded Cisco’s Women in Cybersecurity Community which focuseson developing the next generation of women cybersecurity leaders. In 2016 she received the prestigiousAnita Borg’s 2016 Women of Vision Technology Leadership Award.",
    links: [
        {
            url: "https://www.linkedin.com/in/michele-d-guel-6992993/",
            icon: LinkedinIcon
        }
    ],
    talks: [{
        title: "A Peek into 2040 – The Technology, the Threat and Needed Product Capabilities",
        abstract: "In this fast-paced Keynote, Michele will give her thoughts on common technology in the year 2040 and what may be probable security threats given what we know today.  What we have today will certainly change and how we approach securing our products, our services and our infrastructures much change.   With a view of technology and probably security threats in 2040 we’ll discuss 5 key traits on that are a must for security products (any products really) moving forward."
    }]
}]

const distinguishedResearchForum = [{
    imageSrc: "/img/zhiqiang-lin.jpg",
    position: "Associate Professor of Computer Science and Engineering (CSE) at Ohio State University",
    name: "Zhiqiang Lin",
    profileLink: "zhiqiang-lin",
    description: "",
    links: [
        {
            url: "https://web.cse.ohio-state.edu/~lin.3021/#toc2",
            icon: WebsiteIcon
        }
    ],
    talks: [{
        title: "Uncovering BLuetooth Link Layer Vulnerabilities via Automated Binary Analysis of Bare-metal Firmware",
        abstract: "Being a near-range wireless communication protocol, Bluetooth, particularly its Low Energy version, is ubiquitous in the Internet-of-Things (IoT) devices today because of its extremely lower energy consumption. However, the security and privacy implication of these Bluetooth devices is not well understood. In this talk, Dr. Lin will present how to use binary analysis to identify the security vulnerabilities in real-world Bluetooth devices. In particular, he will talk about FirmXRay (https://github.com/OSUSecLab/FirmXRay), an open-source tool developed by his group to automatically analyze the bare-metal firmware of Bluetooth devices to identify the link-layer vulnerabilities such as insecure pairing and unauthorized read/write. The details of how to develop this tool and the identified vulnerabilities will be shared in this talk."
    }]
},
{
    imageSrc: "/img/attila.jpg",
    position: "Assistant Professor, The Department of Computer Science and Engineering, University of South Florida",
    name: "Attila Yavuz",
    profileLink: "attila-yavuz",
    description: "",
    links: [
        {
            url: "https://www.csee.usf.edu/~attilaayavuz/",
            icon: WebsiteIcon
        }
    ],
    talks: [{
        title: "Energy Efficient Digital Signatures for Embedded Medical Devices",
        abstract: "Embedded medical devices collect security-critical healthcare data whose authentication and integrity must be guaranteed. Although symmetric cryptography is efficient, it lacks public verifiability and non-repudiation that are necessary for medical auditing and dispute resolution. Digital signatures provide these features, but existing alternatives are extremely energy costly for battery-limited embedded medical devices. In this talk, we present light-weight digital signatures that are specifically designed for highly resource-limited devices. We will describe Signer Efficient Multiple-time Elliptic Curve Signature (SEMECS) that achieves optimal signature and private key sizes for an EC-based signature without requiring any EC operation (e.g., EC scalar multiplication or addition) at the signer. Our experiments on an 8-bit AVR microprocessor confirm up to 19x less battery-consumption for SEMECS as compared to its fastest counterpart SchnorrQ. We will also discuss Energy-aware digital signatures for Embedded Medical devices (ESEM) that offer polynomially unbounded signing capability with a distributed verification. With their optimal signing efficiency and provable security, SEMECS and ESEM are ideal digital signatures for embedded medical devices."
    }]
},
{
    imageSrc: "/img/Luis-Bathen.png",
    position: "Research Staff, IBM Research",
    name: "Luis Bathen",
    profileLink: "Luis-Bathen",
    description: "",
    links: [
        {
            url: "https://www.linkedin.com/in/bathen/",
            icon: LinkedinIcon
        }
    ],
    talks: [{
        title: "Decentralized Identity at the Edge",
        abstract: "Decentralized identity has become a hot topic since the emergence of blockchain technology. Community interest has gone beyond simple user-based key management onto standardization of decentralized identifiers. Similarly, as connectivity continues to evolve and the imminent deployments of 5G networks, edge computing has become more and more a reality. This talk explores use-cases of decentralized identity at the edge, including the use in autonomous vehicles and biometrics."
    }]
}
]


const tutorials = [
    {
        category: "By Trend Micro",
        date: "December 19, 2020 , 9:30 a.m. – 12 p.m. (PST)",
        title: "Tutorial Session 1: Cybersecurity in Digital Transformation by Trend Micro team (Session Chair: Mitchel Chang)",
        zoom: "https://trendmicro.zoom.us/j/92053131593?pwd=djgxVHkxT09GZk5EbU9wQ0N0aWFlUT09",
        topics: [
            {
                title: "Cybersecurity in Digital Transformation (Industry 4.0)",
                speakers: "Mitchel Chang, SVP, CSR and Education, Trend Micro",
                links: [
                    {
                        url: "https://www.linkedin.com/in/mitchelchang",
                        icon: LinkedinIcon
                    }
                ],
                videoLink : "https://www.youtube.com/watch?v=4M5SQX-r-TA&feature=youtu.be"
            },
            {
                title: "IoT Threats and Solutions",
                speakers: "Jerry Liao, Senior Director of R&D, Trend Micro",
                videoLink : "https://www.youtube.com/watch?v=4M5SQX-r-TA&t=478&feature=youtu.be"
            },
            {
                title: "Industrial IoT, IT and OT Convergence",
                speakers: "Richard Ku, SVP, Commercial IoT Market Development, Trend Micro",
                videoLink: "https://www.youtube.com/watch?v=4M5SQX-r-TA&feature=youtu.be"
            },
            {
                title: "Enterprise Threat Management",
                speakers: "Steven Allison, Senior Solution Consultant, Trend Micro",
                videoLink: "https://www.youtube.com/watch?t=5439&v=4M5SQX-r-TA&feature=youtu.be"
            }
        ],
    },
    {
        category: "By experts in Blockchain and Security",
        date: "December 19, 2020 , 12:30 p.m. – 4 p.m. (PST)",
        title: "Tutorial Session 2: Blockchain and Security",
        topics: [
            {
                title: "Evolution of Consensus Mechanisms on the Permissionless Blockchain",
                speakers: "Thomas H. Austin, Associate Professor, Computer Science Department in San Jose State University.",
                links: [
                    {
                        url: "https://www.svcsi.online/thomas-bio",
                        icon: WebsiteIcon
                    }
                ],
                summary: "In Bitcoin, miners achieve consensus on transactions through a proof-of-work protocol. We will review how Bitcoin's consensus mechanism has evolved and review challenges it faces: the rise of ASIC miners, mining pools, selfish-mining attacks,and increased centralization. We will review proposed solutions for defending against these attacks, and also discuss how other blockchain consensus mechanisms attempt to address these problems. We will review proof-of-stake protocols like Peercoin and Tendermint and discuss their advantages and limitations compared to Bitcoin."
            },
            {
                title: "Blockchain Networking Security",
                speakers: "Sang-Yoon Chang, Assistant Professor at the Computer Science Department in University of Colorado Colorado Springs.",
                links: [
                    {
                        url: "https://academics.uccs.edu/~schang2",
                        icon: WebsiteIcon
                    }
                ],
                summary: "Blockchain uses the distributed peer-to-peer (P2P) networking. The networking is critical because it delivers information, such as new blocksand transactions, and enables the distributed consensus protocol and the rest of the blockchain operations. In this talk, we will review the unique security challenges in blockchain networking, the vulnerabilities and threats, the threat impacts to the blockchain application, and the potential countermeasures."
            },
            {
                title: "Ethereum Smart Contracts Development",
                speakers: "Dan Nolan, Co-Founder at ChainShot Inc.",
                links: [
                    {
                        url: "https://www.linkedin.com/in/dannolan99/",
                        icon: LinkedinIcon
                    }
                ],
                summary: "Developing smart contracts is a fantastic way to learn about the Ethereum ecosystem. Let's get hands-on in this interactive coding session and build our own smart contracts while integrating them into an existing Ethereum protocol on the application layer. Then we'll learn how to set up these smart contracts in a local environment for further development."
            }
        ],
    }
]

const fundRaising = [{
    imageSrc: "img/front_large_extended.jpg",
    name: "Hanes Beefy T-shirt",
    link: "https://www.customink.com/fundraising/svcc2020?side=front&type=1&zoom=false",
    description: "Available in white, ash  & heather blue",
},
{
    imageSrc: "img/front_large_extended_black.jpg",
    name: "Hanes Beefy T-shirt",
    link: "https://www.customink.com/fundraising/3svcc2020?side=front&type=2&zoom=false",
    description: "Available in black & light Steel",
}]

export { keyNoteSpeakers, otherSpeakers, careerPanelists, distinguishedResearchForum, tutorials , fundRaising };
