import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "../../components/misc/Headings";
import FAQ from "../../components/faqs/SingleCol.js";
import Pricing from "../../components/pricing/ThreePlansWithHalfPrimaryBackground.js";
import MainFeature from "../../components/features/TwoColWithButton.js";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Chairs & Judges:" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
      <MainFeature 
        // subheading="Supported by Cisco"
        heading="Blockchain Hackathon on ethereum (BSH)"
        imageSrc={require("images/bsh.png")}
        buttonRounded={false}
        description={
            "We provide two dAPPs on Ethereum. Each student team needs to provide a solution for a specific challenging problem given by the hackathon organizers on 12/17/2020 at 6 p.m. The registered users must attend our free blockchain tutorial session on 12/17/2020 at 6 p.m. - 8 p.m. to understands the problem set in advance. Each student has programming skills (especially solidity) and understand Ethereum before the tutorial session."
        }
        primaryButtonText="Register"
        primaryButtonUrl="https://cs-room.com/event/bp/5f6320cf0e102309a0e2f68a"
      />
        <ContentWithPaddingXl>
          {/* <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow> */}
          <Text>
          <h2>Hackathon Details:</h2>
            <br/>
                <ul>
                    <li><strong>Hackathon Date : </strong>December 18, 2020  -  9 a.m to December 19, 2020 - 3 p.m.</li>
                    <li><strong>Award Announcement : </strong> We will announce the final winner within 3 days after the event because we need to evaluate each team. We will post the list here on our website. </li>
                    <li><strong>The number of students per team is 2.</strong> Each of the two students in the same team needs to register this blockchain hackathon event separately. But, when you register this blockchain track, please leave your note about your partner (name, affiliation, email address.)</li>
                    <li>Video recording submission about your presentation and demo within 5 minutes. </li>
                    <li>Submit your code and presentation slides through DropBox. (We will share our DropBox link later.)</li>
                </ul>
            <h2>BSH Chairs:</h2>
            <br/>
                <ul>
                    <li>Sang-Yoon Chang, UCCS</li>
                    <li>Gokay Saldamli , SJSU </li>
                </ul>
            <h2>BSH Judges:</h2>
            <br/>
                <ul>
                    <li>Divyesh Jadav, IBM Research</li>
                    <li>Elizabeth K, Ethereum Classic Labs</li>
                    <li>Zach Belford, ETC Core</li>
                    <li>Sang-Yoon Chang, University of Colorado Colorado Spring</li>
                    <li>Gokay Saldamli, San Jose State University</li>
                    <li>Thomas Austin, San Jose State University, </li>
                    <li>Dan Nolan, ChainShot, Inc.</li>
                </ul>  
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
