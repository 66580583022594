import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import { Container } from "../misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "../headers/light.js";
import Footer from "../footers/MiniCenteredFooter.js";
import { SectionHeading } from "../misc/Headings";
import FAQ from "../faqs/SingleCol.js";
import Pricing from "../pricing/ThreePlansWithHalfPrimaryBackground.js";
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-2`;
const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto pt-8 pb-24 lg:pt-8 lg:pb-24`;
const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const Text = styled.div`
  ${tw`text-lg text-gray-800 mb-8`}
  p {
    ${tw`my-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "IOT CYBERSECURITY HACKATHON FOR STUDENTS" }) => {
    return (
        <AnimationRevealPage>
            <Header />
            <Container style={{ textAlign: "justify", textJustify: "interWord" }}>
                <ContentWithPaddingXl>
                    <div className="">
                        <h1 style={{ "color": "#6415FF", fontSize: "2.5rem", fontWeight: "700", textAlign: "center", marginTop: "0px", marginLeft: "0px" }}>
                            Agenda
                        </h1>
                    </div>
                    <HeadingRow>
                        <Heading>Conference Overview</Heading>
                    </HeadingRow>
                    <Text>
                        <p style={{ fontWeight: "700" }}>Silicon Valley Cybersecurity Conference 2021</p>
                        <p style={{ fontSize: "16px" }}>
                            The Silicon Valley Cybersecurity Conference provides opportunities to learn
                            cutting-edge technical topics and career development skills in cybersecurity.
                            The conference connects with accomplished researchers and technologists
                            and fellow students for inspiration and support through a wealth of
                            opportunities. We also encourage unrepresented groups to participate in this
                            cybersecurity community to build up their careers with experts in Silicon
                            Valley.
                        </p>
                    </Text>
                    <Text>
                        <p style={{ fontWeight: "700" }}>Research Paper Sessions with Keynote speakers (Dec. 17-18, 2020)</p>
                        <p style={{ fontSize: "16px" }}>
                            The conference features morning and afternoon research paper sessions.
                            During these sessions, featured keynote speakers in the morning and lunch
                            time will share highlights from their professional experience that impact career
                            pathways in cybersecurity.
                        </p>
                    </Text>
                    <Text>
                        <p style={{ fontWeight: "700" }}>Unrepresented Groups in Cybersecurity (UNiSEC) & Career Panels (Dec. 18, 2020)</p>
                        <p style={{ fontSize: "16px" }}>
                            The conference is an opportune time to ask underrepresented leaders for
                            guidance and feedback about the important decisions you face at your
                            workplace and for your career. This session has featuring distinguished
                            women leaders to discuss future cybersecurity technology and vision.
                        </p>
                    </Text>
                    <Text>
                        <p style={{ fontWeight: "700" }}>Tutorial Sessions (Dec. 19, 2020)</p>
                        <p style={{ fontSize: "16px" }}>
                            There are two parallel tutorial sessions: Blockchain with Chainshot Inc. and
                            Cybersecurity with Trend Micro. The tutorials provide experience for cutting-
                            edge technologies and explore their possibilities in your career aspirations
                            and plans.
                        </p>
                    </Text>
                    <Text>
                        <p style={{ fontWeight: "700" }}>Student Cybersecurity Hackathon (Dec. 19, 2020)</p>
                        <p style={{ fontSize: "16px" }}>
                            This conference hosts two hackathons: network security and blockchain. The
                            students will work on specific problem sets that our hackathon organizers
                            provide. The participants will expose to cybersecurity problems and real-world
                            problems to solve with their technical knowledge.
                        </p>
                    </Text>
                    <Text>
                        <p style={{ fontWeight: "700" }}>Virtual Conference Community on Zoom (Dec. 17-19, 2020)</p>
                        <p style={{ fontSize: "16px" }}>
                            The conference provides several zoom links to connect with peers, potential
                            future co-workers, hiring managers, and mentors at the Zoom sessions.
                        </p>
                    </Text>
                </ContentWithPaddingXl>
            </Container>
            <Footer />
        </AnimationRevealPage>
    );
};
