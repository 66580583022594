import React from "react";
import tw from "twin.macro";
import Head from "./Head";
import Header from "../../components/headers/light.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { ReactComponent as QuotesLeftIcon } from "../../images/quotes-l.svg";
import { ReactComponent as QuotesRightIcon } from "../../images/quotes-r.svg";
import { LinkedinFilled } from '@ant-design/icons';
import { Row, Col } from "antd";

const HeroContainer = tw.div`z-20 relative max-w-screen-xl mx-auto`;

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const QuoteContainer = tw.div`relative p-6 md:p-8 lg:p-10 mt-4 md:mt-0`;
        const Quote = tw.blockquote`text-center md:text-left font-medium text-xl lg:text-lg xl:text-lg`;
        const QuotesLeft = tw(QuotesLeftIcon)`w-8 h-8 lg:w-10 lg:h-10 text-primary-500 absolute top-0 left-0`;
        const QuotesRight = tw(QuotesRightIcon)`w-8 h-8 lg:w-10 lg:h-10 text-primary-500 absolute bottom-0 right-0`;
        const subHead = "2rem"
        return (
            <AnimationRevealPage>
                <HeroContainer>
                    <Header />
                    <div className="mx-2">
                        <h1 style={{ "color": "#6415FF", fontSize: "2.5rem", fontWeight: "700", textAlign: "center", marginTop: "40px", marginLeft: "0px" }}>
                            CONFERENCE ORGANIZERS
                        </h1>
                        <Row>
                            <Col
                                className="md:ml-24 lg:ml-24 xl:ml-24"
                            >
                                <div>
                                    <h1 style={{ "color": "#6415FF", fontSize: "1.70rem", fontWeight: "700", textAlign: "left", marginTop: "20px", marginLeft: "0px" }}>
                                        Program Chair
                                    </h1>
                                    <ul role="list" className="chair-name ml-3 text-gray-600">
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Divyesh Jadav, IBM Research</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Younghee Park, San Jose State University</span></li>
                                    </ul>
                                </div>
                                <div>
                                    <h1 style={{ "color": "#6415FF", fontSize: "1.70rem", fontWeight: "700", textAlign: "left", marginTop: "20px", marginLeft: "0px" }}>
                                        Local/Industry Organization Chair
                                    </h1>
                                    <ul role="list" className="chair-name ml-3 text-gray-600">
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Michael Tjebben, CISCO</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Sang-soo Lee, San Jose State University</span></li>
                                    </ul>
                                </div>
                                <div>
                                    <h1 style={{ "color": "#6415FF", fontSize: "1.70rem", fontWeight: "700", textAlign: "left", marginTop: "20px", marginLeft: "0px" }}>
                                        Publicity Chair
                                    </h1>
                                    <ul role="list" className="chair-name ml-3 text-gray-600">
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Sara Tehranipoor, Santa Clara University</span></li>
                                    </ul>
                                </div>
                                <div>
                                    <h1 style={{ "color": "#6415FF", fontSize: "1.70rem", fontWeight: "700", textAlign: "left", marginTop: "20px", marginLeft: "0px" }}>
                                        Panel Chair
                                    </h1>
                                    <ul role="list" className="chair-name ml-3 text-gray-600">
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Sang-Yoon Chang, University of Colorado Colorado Spring</span></li>
                                    </ul>
                                </div>
                                <div>
                                    <h1 style={{ "color": "#6415FF", fontSize: "1.70rem", fontWeight: "700", textAlign: "left", marginTop: "20px", marginLeft: "0px" }}>
                                        Publication Chair
                                    </h1>
                                    <ul role="list" className="chair-name ml-3 text-gray-600">
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Thomas Austin, San Jose State University</span></li>
                                    </ul>
                                </div>
                                <div>
                                    <h1 style={{ "color": "#6415FF", fontSize: "1.70rem", fontWeight: "700", textAlign: "left", marginTop: "20px", marginLeft: "0px" }}>
                                        Registration Chair
                                    </h1>
                                    <ul role="list" className="chair-name ml-3 text-gray-600">
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Nima Karimian, San Jose State University</span></li>
                                    </ul>
                                </div>
                                <div>
                                    <h1 style={{ "color": "#6415FF", fontSize: "1.70rem", fontWeight: "700", textAlign: "left", marginTop: "20px", marginLeft: "0px" }}>
                                        Technical Program Committee
                                    </h1>
                                    <ul role="list" className="chair-name ml-3 text-gray-600">
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Andrea VISCONTI, Università degli Studi di Milano</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Samaneh Ghandali, Google</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Melike Erol-Kantarci, University of Ottawa</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Qiong Zhang, Fujitsu Network Communications</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Hyoungshick Kim, Sungkyunkwan University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Attila Altay Yavuz, University of South Florida</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Sunil K Cheruvu, Intel</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Donghyun (David) Kim, Georgia State University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Hongxin Hu, Clemson University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Hongda Li, Palo Alto Networks</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Junbeom Hur, Korea University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Nima Karimian, San Jose State University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Chintan Bhatt, Charotar University of Science And Technology</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Ben Reed, San Jose State University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Wenjun Fan, University of Colorado Colorado Springs</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Hsiang-Jen Hong, University of Colorado Colorado Springs</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Brett Meadows, MITRE, University of Colorado Colorado Springs</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Chang-Wu Chen, AMIS</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Levent Ertaul, CSU East Bay</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Igor Vasiltsov, Samsung Electronics</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Lo'ai Tawalbeh, Texas A&M University-San Antonio</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Jinoh Kim, Texas A&M University, Commerce</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Wei Yan, Clarkson University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Tamzidul Hoque, University of Kansas</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Hossein Sayadi, California State University, Long Beach</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Arman Roohi, University of Nebraska at Lincoln</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Paul Wortman, University of Connecticut</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Samah Saeed, The City College of New York</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Sara Tehranipoor, Santa Clara University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Prabha Sundaravadivel, The University of Texas at Tyler</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Sang-Yoon Chang, University of Colorado Colorado Springs</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Fabio Di Troia, San Jose State University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Yasuyuki Nogami, Okayama University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Thomas Austin, San Jose State University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Gokay Saldamli, San Jose State University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Venkat Pullela, Broadcom</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Kohei Shiomoto, Tokyo City University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Ahmed Elmesiry, University of South Wales, UK</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Ah young Lee, Kennesaw State University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Daehee Seo, SangMyung University</span></li>
                                        <li data-icon="▸" className="mr-2"><span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Zhang Tianwei, Nanyang Technological University</span></li>
                                    </ul>
                                </div>

                            </Col>
                        </Row>
                    </div>
                </HeroContainer>
                <Footer />
            </AnimationRevealPage >
        )
    }
}

export default Main;