import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-6.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import Agenda from "../Main";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons';

const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlanDurationSwitcher = tw.div`block w-full max-w-xl sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1 mt-8`;
const SwitchButton = styled.button`
  ${tw`w-1/2 sm:w-32 px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${props => props.active && tw`bg-primary-500 text-gray-100`}
`;
const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto py-10 lg:py-12`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-2/3 -translate-y-1/2`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300`}
`;

const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl h-24 border-b`;
const LeftColumn = tw.div`relative lg:w-4/12 lg:mt-0 flex flex-col lg:items-center lg:text-center font-bold text-xs justify-center  h-full  border-l px-4 border-gray-500 bg-red-100`;
const LeftColumn2 = tw.div`relative lg:w-8/12 lg:mt-0 flex flex-col lg:items-center lg:text-center font-bold text-xs justify-center  h-full  border-l px-4 border-gray-500 bg-gray-100`;

const DateColumnTop = tw.div`relative flex flex-col lg:flex-row text-center lg:items-center lg:w-4/12 text-sm font-bold justify-center h-full border-l border-r border-gray-500`;
const DateColumnTop2 = tw.div`relative flex flex-col lg:flex-row text-center lg:items-center lg:w-4/12 text-sm font-bold justify-center h-full border-l  border-gray-500`;
const Day3 = tw.div`relative flex flex-col lg:flex-row text-center lg:items-center lg:w-4/12 text-xs font-bold justify-center h-full border-r border-l border-gray-500 bg-indigo-100`;
const Day2 = tw.div`relative flex flex-col lg:flex-row text-center lg:items-center lg:w-4/12 text-xs font-bold justify-center h-full  border-l border-gray-500 bg-gray-200 `;

const Day2RightColumn = tw.div`relative  lg:w-6/12 lg:mt-0 flex flex-col justify-center font-bold text-xs border-r h-full `;
const RightColumn = tw.div`relative lg:w-6/12 lg:mt-0 flex flex-col justify-center font-bold text-xs border-r h-full`;
const TopLeftColumn = tw.div`relative lg:w-4/12 lg:mt-0 flex flex-col lg:items-center text-center  font-bold text-sm justify-center border-l  h-full border-gray-500`;
const TopLeftColumn2 = tw.div`relative lg:w-8/12 lg:mt-0 flex flex-col lg:items-center text-center  font-bold text-sm justify-center border-l  h-full border-gray-500`;

const SecondColumn = tw.div`relative lg:w-6/12 flex-shrink-0 text-center lg:text-left font-bold`;
const ThirdColumn = tw.div`relative mt-12 lg:w-6/12 lg:mt-0 flex flex-col justify-center `;
const TwoColumnDual = tw.div`relative flex flex-col lg:flex-row md:items-center lg:w-8/12  `;
const DateColumn = tw.div`relative flex flex-col lg:flex-row md:items-center lg:w-4/12 justify-center h-full`;

export default ({
    subheading = "Agenda",
    heading = "Agenda",
    description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    plans = null,
    primaryButtonText = "Buy Now",
    planDurations = [
        {
            text: "Overview",
            switcherText: "Overview",
            twoColumns: false
        },
        {
            text: "Dec 17",
            switcherText: "Dec 17",
            twoColumns: true
        },
        {
            text: "Dec 18",
            switcherText: "Dec 18",
            twoColumns: false
        },
        {
            text: "December 19",
            switcherText: "Dec 19",
            twoColumns: true
        }
    ]
}) => {
    const agenda = [
        {
            "time": "08:20 am to 08:30 am",
            "category":{ "title": "Opening Remark","raffle": 0 },
            "description": [
                { "title": 'Opening Remark for Hackathon (Sheryl H. Ehrman, Dean in College of Engineering, SJSU)', "raffle": 1 },
            ],
        },
        {
            "time": "08:30 am to 09:20 am",
            "category":{ "title": "Keynote","raffle": 1 },
            "description": [
                { "title": 'Keynote Speaker (Qiong Zhang, Fujitsu Laboratories of America) (Session Chair: Younghee Park)', "raffle": 1 },
            ]
        },
        {
            "time": "09:30 am to 12:00 pm",
            "category":{ "title": "Tutorial","raffle": 1 },
            "hackathon": 1,
            "description": [
                 { "title": 'Tutorial Session 1: Cybersecurity in Digital Transformation by Trend Micro team (Session Chair: Mitchel Chang)', "raffle": 1 },
                 { "title": 'Topic 1. Cybersecurity in Digital Transformation (Industry 4.0) (Mitchel Chang, SVP, CSR and Education, Trend Micro)', "raffle": 1 },
                 { "title": 'Topic 2. IoT Threats and Solutions (Jerry Liao, Senior Director of R&D,  Trend Micro)', "raffle": 1 },
                 { "title": 'Topic 3. Industrial IoT, IT and OT Convergence (Richard Ku, SVP, Commercial IoT Market Development, Trend Micro)', "raffle": 1 },
                 { "title": 'Topic 4. Enterprise Threat Management (Steven Allison, Senior Solution Consultant, Trend Micro)', "raffle": 1 },
            ]
        },
        {
            "time": "12:00 pm- 12:30 pm",
            "hackathon": 1,
            "category":{ "title": "Lunch","raffle": 0 },
            "description": [
                { "title": '', "raffle": 0 },
            ]
        },
        {
            "time": "12:30 pm - 16:00 pm",
            "hackathon": 1,
            "category":{ "title": "Tutorial","raffle": 1 },
            "description": [
                 { "title": 'Tutorial Session 2: Blockchain and Security (Session Chair: Thomas H. Austin)', "raffle": 0 },
                 { "title": 'Topic 1: Evolution of Consensus Mechanisms on the Permissionless Blockchain (Thomas H. Austin, SJSU)', "raffle": 0 },
                 { "title": 'Topic 2: Blockchain Networking Security (Sang-Yoon Chang, University of Colorado Colorado Springs)', "raffle": 0 },
                 { "title": 'Topic 3: Ethereum Smart Contracts Development (Dan Nolan, Co-Founder at ChainShot Inc. )', "raffle": 0 },
            ]
        },
        {
            "time": "16:00 pm - 16:30 pm",
            "category":{ "title": "Award Ceremony","raffle": 0 },
            "description": [
                 { "title": 'Award Announcement/Closing Conference ', "raffle": 0 },
            ]
        },
    ];

    const [activeDurationIndex, setActiveDurationIndex] = useState(0);
    // const currAgenda = agenda[activeDurationIndex];
    return (
        <Container>
            <div style={{ textAlign: "center" }} className="mt-5 text-sm text-gray-900">
                <span style={{ fontSize: "1.2rem", fontWeight: "600" }} className="text-black">Enter To Win !!!</span>
                <br />
                Each session (Marked with Raffle <FontAwesomeIcon icon={faTicketAlt} style={{ color: "orange" }} />) will have a raffle to select a winner who will receive a $20 Amazon gift card. 
                <br/>Each session will have more than one raffle times and each participant can receive more than one gift card for different sessions.
                </div>
            <ContentWithPaddingXl>
                <table class="table-auto self-center w-full">
                        <thead>
                            <tr className="h-24">
                                <th class="px-4 py-2 w-2/12  border-2 bg-blue-200 font-bold text-sm ">All timings in PST</th>
                                <th class="px-4 py-2 w-2/12  border-2 bg-blue-200 font-bold text-sm ">Category</th>
                                <th class="px-4 py-2  bg-blue-200 border-2 font-bold text-sm" colSpan={2}>December 19</th>
                            </tr>
                        </thead>
                        <tbody>
                {agenda.map(currAgenda => {
                    return (
                        <>
                            <tr>
                                <td class="border-2 px-4 py-2 font-bold bg-gray-200 text-sm" rowSpan={currAgenda.description.length}>{currAgenda.time}</td>
                                <td class="border-2 px-4 py-2 font-bold bg-red-100 text-sm" rowSpan={currAgenda.description.length}>{currAgenda.category.title} {currAgenda.category.raffle === 1 && <FontAwesomeIcon icon={faTicketAlt} style={{ color: "orange" }} />} </td>
                                <td class="border-2 px-4 py-2 font-bold bg-yellow-100 text-sm" colSpan={!currAgenda.hackathon && 2}>{currAgenda.description[0]["title"]}</td>
                                {currAgenda.hackathon && <td class="border-2 px-4 py-2 font-bold bg-green-100 text-sm" rowSpan={currAgenda.description.length}>Hackathon</td>}
                            </tr>
                            {currAgenda.description.map((d, index) => {
                              if(index> 0){
                                return(
                                    <tr className="">
                                        <td class="border-2 bg-yellow-100 px-4 py-2 font-bold text-sm">{d.title}</td>
                                    </tr>
                                        )
                              }
                            })}
                        </>
                    )
                })}
                   </tbody>
                    </table>         
            </ContentWithPaddingXl>
            <DecoratorBlob1 />
            <DecoratorBlob2 />
        </Container>
    );
};
