import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import { Container } from "../misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "../headers/light.js";
import Footer from "../footers/MiniCenteredFooter.js";
import Indexation from "./Indexation";
import { SectionHeading } from "../misc/Headings";
import FAQ from "../faqs/SingleCol.js";
import Pricing from "../pricing/ThreePlansWithHalfPrimaryBackground.js";
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-2`;
const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto pt-8 pb-24 lg:pt-8 lg:pb-24`;
const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const Text = styled.div`
  ${tw`text-lg text-gray-800`}
  p {
    ${tw`my-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "IOT CYBERSECURITY HACKATHON FOR STUDENTS" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container style={{ textAlign: "justify", textJustify: "interWord" }}>
        <ContentWithPaddingXl>
          <div className="">
            <h1 style={{ "color": "#6415FF", fontSize: "2.5rem", fontWeight: "700", textAlign: "center", marginTop: "0px", marginLeft: "0px" }}>
              SUBMISSION
            </h1>
          </div>
          <HeadingRow>
            <Heading>Paper Submission</Heading>
          </HeadingRow>
          <Text>
            <p style={{ fontSize: "16px" }}>
              The conference solicits research papers describing novel and previously unpublished scientific contributions to the field of cybersecurity in diverse areas. The authors need to submit your original paper to EasyChair.
            </p>
          </Text>
          <Text>
            <p style={{ fontWeight: "600" }}>Two different types of papers can be submitted:</p>
            <ul>
              <li style={{ fontSize: "16px" }}>Regular papers (7-8 pages IEEE double-column conference proceeding format)</li>
              <li style={{ fontSize: "16px" }}>Short papers (4-5 pages IEEE double-column conference proceeding format)</li>
            </ul>
          </Text>
          <Text style={{ fontSize: "16px" }}>
            <p>Regular papers should describe novel and previously unpublished scientific contributions to the field of cybersecurity. Each regular paper is limited to 7-8 pages, including tables, figures, and references.</p>
            <p>
              Short papers aim at presenting novel work in progress, novel applications, and novel industry perspective. Each short paper is limited to 4-5pages, including tables, figures, and references. Short papers will also be peer-reviewed, however, they will be evaluated with a focus on the potential for establishing new ideas and for sparking the interest of participants.
            </p>
            <p>
              All papers must be written in English. Authors can purchase extra pages up to 2 pages. Manuscripts must include a title, an abstract with 200-250 words, and a list of 4-6 keywords. All papers must be prepared in the
              <a href="https://www.ieee.org/conferences/publishing/templates.html" target="_blank" style={{ color: "orange" }}>
                <u>
                  &nbsp;IEEE double-column proceedings format.
                </u>
              </a>
            </p>
            <p>
              SVCC 2020 uses a <strong>double-blind review policy</strong>. Authors are required to remove their names, affiliation(s) and other identifying information from the header of the manuscript. This also includes meta-data in the submitted document as well as acknowledgement sections. <strong>Papers that do not meet these anonymization requirements may be rejected without further review.</strong>
            </p>
            <p>
              Authors are encouraged, but not required, to cite their previous work in a neutral manner, for example, avoid “in our previous work [3]” and instead use “as shown in [3]”.
            </p>
            <p>
              All submitted papers will be peer-reviewed. The name(s) of the author(s) will not be visible to the reviewers of a paper. The name(s) of the author(s) will be visible in the submission system to the Program Committee Chairs. Authors should report any conflict of interest with the list of Program Committee members during submission of the manuscript, in which case the Program Committee Chairs will exclude the corresponding PC member(s) from reviewing the paper.
            </p>
            <p>
              <strong>Authors must submit their manuscripts via the following link by November 21, 2020, 23:59 p.m in PDT (No Deadline Extension):</strong>
            </p>
            <div className="my-4">
              <PrimaryButton as="a" target="_blank" href="https://easychair.org/conferences/?conf=svcc2020">Submit a Paper</PrimaryButton>
            </div>
            <p>
              <a href="https://easychair.org/conferences/?conf=svcc2020" target="_blank" style={{ color: "orange" }}>
                <u>
                  https://easychair.org/conferences/?conf=svcc2020
                </u>
              </a>
            </p>
          </Text>
          <HeadingRow style={{ marginTop: "30px" }}>
            <Heading>Paper Publication <span style={{fontSize:"1.25rem"}}> (Accepted full papers will be published in the special issues of the SCIE journals!)</span></Heading>
          </HeadingRow>
          <Text style={{ fontSize: "16px" }}>
            <p>
              <strong>All accepted full papers (not short papers) will be published by an open article system and special issues with the current following journals with the extended version</strong>.
             If the journal reviewers are satisfied with the revisions made to address the weaknesses identified during the initial review process at this conference, this category of papers will be published in the special issue.
             <strong>The selected two best full papers will be waived for this journal processing fee.</strong>
            </p>
            <ul>
              <li style={{ fontSize: "16px" }}>
                <a href="https://www.mdpi.com/journal/sensors/special_issues/BTDI_IoT" target="_blank" style={{ color: "orange" }}>
                  <u>
                    Special issue “Building Trustworthy and dependable Infrastructure for in Internet of Things” in Journal of MDPI Sensor (impact factor: 3.275)
                </u>
                </a>
              </li>
            </ul>
            <p>
              All the accepted original papers as for preprint will be available through our SVCSI website as a digital book with an ISBN number as a conference proceeding. We can also invite accepted short papers if the authors can extend their manuscripts to be suitable for the special issue through a full review process. We will ask the authors to improve their accepted papers within two and a half months after finishing the conference. The authors need to pay the journal processing fee for this special issue.
              Among the accepted full papers, we can also invite <strong>two full best papers</strong> to the following special issue with free of charges if your topic matches with the following special issue.
            </p>
            <ul>
              <li style={{ fontSize: "16px" }}>
                <a href="https://www.mdpi.com/journal/sensors/special_issues/SN_CP" target="_blank" style={{ color: "orange" }}>
                  <u>
                    Special Issue "Smart Sensor Networking in CyberPhysical Systems" Journal of MDPI Sensor (impact factor: 3.275)
                </u>
                </a>
              </li>
            </ul>
            <p>
              <strong>For all accepted papers including short papers, the author can choose a fast track of </strong><a href="https://conceptechint.net/index.php/CFATI" target="_blank" style={{ color: "orange" }}> International Journal of Cyber Forensics and Advanced Threat Investigations</a> <strong><i>(Open Access - Free Processing Fees) after extending the submission. </i>
              Each accepted paper for this conference is required to be registered by one of its authors, and at least one author is required to attend and present the paper during  30-40 minutes Q&A  online at the conference for the paper to be included in the final technical program and the Digital Library. Journal publications require extra publication fees according to the journal publisher policy.</strong>
            </p>
            {/* <p>
              All the accepted original papers as for preprint will be available through our SVCSI website as a digital book with an ISBN number as a conference proceeding. We can also invite accepted short papers if the authors can extend their manuscripts to be suitable for the special issue through a full review process. We will ask the authors to improve their accepted papers within two and a half months after finishing the conference.
            </p>
            <p>
              Each accepted paper for this conference is required to be registered by one of its authors, and at least one author is required to attend and present the paper during 25 minutes including Q&A  online at the conference for the paper to be included in the final technical program and the Digital Library. Journal publications require extra publication fees according to the journal publisher policy.
            </p> */}
          </Text>
          <Text style={{ fontSize: "18px", textAlign: "center", color: "#6415ff", fontWeight: "600" }} className="pt-4">
          All accepted full papers will be published by the special issues of the SCIE Journals as listed here. The selected best full papers will be waived for the journal processing fee.
          </Text>
        </ContentWithPaddingXl>
        <Indexation/>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
