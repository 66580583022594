import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-6.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import Header from './../components/headers/light'
import Footer from './../components/footers/MiniCenteredFooter.js';
const HeadingRow = tw.div`flex`;
const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlanDurationSwitcher = tw.div`block w-full max-w-xl sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1 mt-8`;
const SwitchButton = styled.button`
  ${tw`w-1/2 sm:w-32 px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${props => props.active && tw`bg-primary-500 text-gray-100`}
`;
const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto py-10 lg:py-12`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-2/3 -translate-y-1/2`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300`}
`;

export default ({
    subheading = "Agenda",
    heading = "Agenda",
    description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    plans = null,
    primaryButtonText = "Buy Now",
    planDurations = [
        {
            text: "Overview",
            switcherText: "Overview",
            twoColumns: false
        },
        {
            text: "Dec 17",
            switcherText: "Dec 17",
            twoColumns: true
        },
        {
            text: "Dec 18",
            switcherText: "Dec 18",
            twoColumns: false
        },
        {
            text: "December 19",
            switcherText: "Dec 19",
            twoColumns: true
        }
    ]
}) => {
    const papers = [
        {
            "title": "Dynamic Security Analysis of Zoom, Google Meet and Microsoft Teams",
            "author": "Nicholas Hunter Gauthier , Mohammad Iftekhar Husain",
            "link": "https://drive.google.com/file/d/17StB4-Co-T3xRvq4CrU0n7G0pSOxmwXV/view?usp=sharing"
        },
        {
            "title": "A Secure Encapsulation Schemes based on Key Recovery System",
            "author": "Taehoon Kim, Wonbin Kim , Daehee Seo , Imyeong Lee",
            "link": "https://drive.google.com/file/d/1HpnPRtEBLquFYuryo6ymLG2n0MR3MIT-/view?usp=sharing"
        },
        {
            "title": "Path Authentication Protocol: Based on a Lightweight MAC and a Nonlinear Filter Generator",
            "author": "Yuki Taketa , Yuta Kodera , Takuya Kusaka , Yasuyuki Nogami",
            "link": "https://drive.google.com/file/d/16Tg3j3YZr_XNzXFPAD_J6rdx2uEjyp5j/view?usp=sharing"
        },
        {
            "title": "STRISA: A New Regulation Architecture to Enforce Travel Rule",
            "author": "Wei-Tek Tsai, Dong Yang, Rong Wang, Kangmin Wang, Weijing Xiang, Enyan Deng",
            "link": "https://drive.google.com/file/d/1f_Cn6E4xcOWe-d0zJjQSkqKQGbRy4w3N/view?usp=sharing"
        },
        {
            "title": "Post-Quantum Hash-Based Signatures for Secure Boot",
            "author": "Panos Kampanakis, Peter Panburana, Michael Curcio, Chirag Shroff",
            "link": "https://drive.google.com/file/d/1K3LA92i35kybV8OCE5goTVszbpo2jn2G/view?usp=sharing"
        },
        {
            "title": "Exploring the Coverage of Existing Hardware Vulnerabilities in Community Standards",
            "author": "Paul A. Wortman, Fatemeh Tehranipoor, John A. Chandy",
            "link": "https://drive.google.com/file/d/1S4lLZ-_2okBl1choPX3YaxPJi81TF1ZB/view?usp=sharing"
        },


        {
            "title": "MurQRI: Encrypted Multi-Layer QR Codes for Electronic Identity Management",
            "author": "Bonha Koo, Taegeun Moon, Hyoungshick Kim",
            "link": "https://drive.google.com/file/d/1L8OLzR8yJAkTU72WGqPmPEwwA5Y5PWbl/view?usp=sharing"
        },


        {
            "title": "SpartanGold: A Blockchain for Education, Experimentation, and Rapid Prototyping",
            "author": "Thomas H. Austin",
            "link": "https://drive.google.com/file/d/1lHQKwX4qK--JCJwSLIMdLJd4WpUMuyzC/view?usp=sharing"
        },

        {
            "title": "BIOT: A blockchain-based IoT Platform for Distributed Energy Resource Management",
            "author": "Mostafa Yalpanian , Naser Mirzaei , Alireza Parvizimosaed, Farid Farmani, Mehdi Parvizimosaed, Behdad Bahrami ",
            "link": "https://drive.google.com/file/d/13wip7kMsKs87SH65yhcnnVLmKDaTo0io/view?usp=sharing"
        },
        {
            "title": "A Privacy Preserving E-voting System based on Blockchain",
            "author": "Wenjun Fan†, Shubham Kumar, Vrushali Jadhav, Sang-Yoon Chang and Younghee Park",
            "link": "https://drive.google.com/file/d/12WBmz06cYjENBD5wwrGOIcaQg97soGpw/view?usp=sharing"
        },
        {
            "title": "Reconstructing Classification to Enhance Machine-Learning Based Network Intrusion Detection by Embracing Ambiguity",
            "author": "Chungsik Song, Wenjun Fan, Sang-Yoon Chang and Younghee Park",
            "link": "https://drive.google.com/file/d/1DjKaQBMJRpk9xFAveSrwo2XdfgFkipNb/view?usp=sharing"
        },
        {
            "title": "ConTheModel: Can we modify tweets to confuse classifier models",
            "author": "Aishwarya Ram Vinay, Mohsen Ali Alawami, Hyoungshick Kim",
            "link": "https://drive.google.com/file/d/1kvnp-bTelPvzDsXp4JuYV4xMoG4wClio/view?usp=sharing"
        },
        {
            "title": "A Systematic Approach to Building Autoencoders for Intrusion Detection",
            "author": "Youngrok Song, Sangwon Hyun, Yun-Gyung Cheong",
            "link": "https://drive.google.com/file/d/19kFnH4bIC02oWSQW6XtXmPIrDOG39F0F/view?usp=sharing"
        },


        {
            "title": "Poster: An Attack on Quantum Circuits Based on the Error Rates of NISQ Systems and a Countermeasure",
            "author": "Nikita Acharya , Vedika Saravanan , Samah Mohamed Saeed",
            "link": "https://drive.google.com/file/d/1nTLY4iYUMtGwEedoOkVEZCsopI2SpPSi/view?usp=sharing"
        },
        {
            "title": "POSTER: Cryptocurrency with Multiple Sequence Alignment Proof-of-Work",
            "author": "Yan Chen",
            "link": "https://drive.google.com/file/d/1YnbMXUIMwVQO5e0lgmoBtK2PtsvU3nLF/view?usp=sharing"
        },
        {
            "title": "Poster: IoT Checker Using Timing Side-channel and Machine Learning",
            "author": "Kratika Sahu, Rasika Kshirsagar, Surbhi Vasudeva, Taghreed Alzahrani, and Nima Karimian",
            "link": "https://drive.google.com/file/d/1YnbMXUIMwVQO5e0lgmoBtK2PtsvU3nLF/view?usp=sharing"
        },
        {
            "title": "Poster: New Locked Circuits for Hardware Security",
            "author": "Michael Yue and Fatemeh Tehranipoor",
            "link": "https://drive.google.com/file/d/11NOYLGMfJ6SSQpLlIB2YuZ1S5JTfa2Hg/view?usp=sharing"
        },
        {
            "title": "Poster: Deep Learning for Side-Channel Analysis",
            "author": "Soroor Ghandali and Fatemeh Tehranipoor",
            "link": "https://drive.google.com/file/d/1cV2AK_7fP3O3Sb9UyBVT2VX-1mmL52to/view?usp=sharing"
        },


    ]
    const [activeDurationIndex, setActiveDurationIndex] = useState(0);
    // const currAgenda = agenda[activeDurationIndex];
    return (
        <AnimationRevealPage>
            <Header />
            <Container style={{ textAlign: "justify", textJustify: "interWord" }}>
                <ContentWithPaddingXl>
                    <div className="py-8">
                        <h1 style={{ "color": "#6415FF", fontSize: "2.5rem", fontWeight: "700", textAlign: "center", marginTop: "0px", marginLeft: "0px" }}>
                            Accepted Papers
                        </h1>
                    </div>
                    <table class="table-auto self-center w-full">
                        <thead>
                            <tr className="h-24">
                                <th class="px-4 py-2 text-center bg-blue-200 border-2 font-bold text-sm"></th>
                                <th class="px-4 py-2 text-center bg-blue-200 border-2 font-bold text-sm">Paper Title</th>
                                <th class="px-4 py-2 text-center bg-blue-200 border-2 font-bold text-sm">Author(s)</th>
                                <th class="px-4 py-2 text-center bg-blue-200 border-2 font-bold text-sm">View PDF</th>
                                <th class="px-4 py-2 text-center bg-blue-200 border-2 font-bold text-sm">View Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {papers.map((paper, idx) => {
                                return (
                                    <tr>
                                        <td class="border-2 px-4 py-2  bg-gray-200 text-sm" >#{idx + 1}</td>
                                        <td class="border-2 px-4 py-2  bg-gray-200 text-sm" >{paper.title}</td>
                                        <td class="border-2 px-4 py-2  bg-yellow-100 text-sm">{paper.author}</td>
                                        <td class="border-2 px-4 py-2 bg-pink-100 text-sm text-center"><a href={paper.link} target="_blank">View</a></td>
                                        <td class="border-2 px-4 py-2 bg-pink-100 text-sm text-center"><a href={"/accepted-papers/" + paper.title.split(" ").join("-")} >View Details</a></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </ContentWithPaddingXl>
            </Container>
            <Footer />
        </AnimationRevealPage>
    );
};
